import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --primary-font-color: #005D81;
    --primary-color: #00ADEF;
    --accent-color: #F39200;
    --font-color: #4B4B4B;

    --input-height: 5rem;
    --button-height: 5rem;
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body, p, a, input, h1, h2, textarea, option, select {
    font-family: 'Mulish', sans-serif;
  }

  body, p, a, input, textarea, .react-select__placeholder {
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--font-color);
  }

  input {
    height: var(--input-height);
  }

  input, textarea {
    padding-left: 15px;
    padding-right: 15px;
    resize: none;

    ::placeholder {
      color: #9A9A9A;
    }
  }

  button {
    height: var(--button-height);
  }

  html, body, #root {
    height: 100vh;
  }

  html {
    font-size: 62.5%; // 1 rem = 10px
  }

  @media (max-width: 800px) {
    html {
      font-size: 56.25%; // 1 rem = 9px;
    }
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  button {
    cursor: pointer;
  }

  // Loading indicator
  .loader {
    z-index: 99999;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--primary-color);
    width: 8.5rem;
    height: 8.5rem;
    position: fixed;
    top: 40%;
    left: 48%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  .loader-accent {
    border-top: 16px solid var(--accent-color);
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  //Modal styles
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
`;
