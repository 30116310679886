import { useRef, useState } from 'react';
import { Form } from '@unform/web';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import api from '../../services/api';

import { Container, Title, Text } from './styles';

const BecomeDesigner = () => {
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(false);

  async function handleSubmitForm(data, { reset: resetForm }) {
    formRef.current.setErrors({});
    try {
      setLoading(true);
      await api.post('/contact_form', { ...data, type: 'BECOME_DESIGNER' });

      resetForm();
      Notification({
        message: 'Enviado com sucesso',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      if (err.response && err.response.data.errors) {
        formRef.current.setErrors(err.response.data.errors);
        return;
      }

      const error = err.response
        ? err.response.data.error
        : 'Erro ao enviar formulário';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <Title>Designer</Title>
        <Text>
          Você é um designer e deseja vender seus projetos de corte a laser no
          portal Due It? Preencha o formulário abaixo e nós entraremos em
          contato!
        </Text>
        <Form ref={formRef} onSubmit={handleSubmitForm}>
          <Input type="text" name="name" label="Nome" />
          <Input type="email" name="email" label="E-mail" />
          <Input type="phone" name="phone" label="Telefone" />
          <Input
            type="text"
            name="message"
            label="Mensagem(Opcional)"
            multiline
          />
          <Button primary text="Enviar" type="submit" />
        </Form>
      </Container>
    </>
  );
};

export default BecomeDesigner;
