import { useHistory } from 'react-router-dom';
import Button from '../Button';

import {
  Container,
  Headline,
  LeftContainer,
  CenterContainer,
  RightContainer,
  PersonImage,
} from './styles';

const personImageUrl1 =
  'https://www.fakepersongenerator.com/Face/male/male20161086635265045.jpg';
const personImageUrl2 =
  'https://www.fakepersongenerator.com/Face/female/female20121023527642731.jpg';
const personImageUrl3 =
  'https://www.fakepersongenerator.com/Face/male/male1084842603332.jpg';
const personImageUrl4 =
  'https://www.fakepersongenerator.com/Face/female/female1022892839691.jpg';
const personImageUrl5 =
  'https://www.fakepersongenerator.com/Face/female/female1022727860290.jpg';
const personImageUrl6 =
  'https://www.fakepersongenerator.com/Face/male/male20161086378664184.jpg';
const personImageUrl7 =
  'https://www.fakepersongenerator.com/Face/female/female20151024062417389.jpg';

const BecomeDesignerSection = () => {
  const history = useHistory();

  function handleButtonClick() {
    history.push('/become-designer');
  }

  return (
    <Container>
      <LeftContainer
        backgroundUrl={`${process.env.PUBLIC_URL}/images/designer-section-background-left.png`}
      >
        <div />
        <PersonImage
          src={personImageUrl1}
          alt="Pessoa"
          height={130}
          style={{ alignSelf: 'center' }}
        />
        <PersonImage
          src={personImageUrl2}
          alt="Pessoa"
          height={43}
          style={{ alignSelf: 'center', marginLeft: '50px' }}
        />
        <PersonImage src={personImageUrl3} alt="Pessoa" height={90} />
        <PersonImage
          src={personImageUrl4}
          alt="Pessoa"
          height={43}
          style={{ alignSelf: 'center', marginLeft: '30px' }}
        />
        <PersonImage
          src={personImageUrl5}
          alt="Pessoa"
          height={67}
          style={{ alignSelf: 'flex-end', marginLeft: '20px' }}
        />
        <PersonImage src={personImageUrl6} alt="Pessoa" height={90} />
        <PersonImage
          src={personImageUrl7}
          alt="Pessoa"
          height={63}
          style={{ marginLeft: '50px', alignSelf: 'center' }}
        />
      </LeftContainer>
      <CenterContainer>
        <Headline>
          Você é <br />
          <strong>Designer?</strong>
        </Headline>
        <Button
          text="Quero vender meus projetos"
          primary
          onClick={handleButtonClick}
        />
      </CenterContainer>
      <RightContainer
        backgroundUrl={`${process.env.PUBLIC_URL}/images/designer-section-background-right.png`}
      >
        <div>
          <PersonImage
            src={personImageUrl1}
            alt="Pessoa"
            height={42}
            style={{ alignSelf: 'center', marginLeft: '30px' }}
          />
          <PersonImage
            src={personImageUrl2}
            alt="Pessoa"
            height={136}
            style={{ alignSelf: 'center', marginLeft: '50px' }}
          />
          <div />

          <PersonImage
            src={personImageUrl3}
            alt="Pessoa"
            height={91}
            style={{ alignSelf: 'center', marginLeft: '30px' }}
          />
          <PersonImage
            src={personImageUrl4}
            alt="Pessoa"
            height={41}
            style={{ alignSelf: 'center', marginLeft: '100px' }}
          />
          <PersonImage
            src={personImageUrl5}
            alt="Pessoa"
            height={124}
            style={{ alignSelf: 'flex-end', marginLeft: '20px' }}
          />
          <PersonImage
            src={personImageUrl6}
            alt="Pessoa"
            height={67}
            style={{ alignSelf: 'flex-end' }}
          />
          <PersonImage
            src={personImageUrl7}
            alt="Pessoa"
            height={91}
            style={{ marginLeft: '50px', alignSelf: 'center' }}
          />
          <PersonImage
            src={personImageUrl3}
            alt="Pessoa"
            height={66}
            style={{ alignSelf: 'flex-end' }}
          />
        </div>
      </RightContainer>
    </Container>
  );
};

export default BecomeDesignerSection;
