import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Container, Image, CreatedAt, SeeProject, Situation } from './styles';

const propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
    situation: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

const DesignerProjectCard = ({ project }) => {
  const history = useHistory();
  let situationTagColor;
  let situationTextColor;

  switch (project.situation) {
    case 'Aprovado':
      situationTagColor = 'rgba(128, 203, 196, 0.1)';
      situationTextColor = '#80CBC4';
      break;

    case 'Verificar':
      situationTagColor = 'rgba(255,0,0,0.1)';
      situationTextColor = '#ff0000';
      break;

    case 'Em análise':
      situationTagColor = 'rgba(247, 182, 0, 0.12)';
      situationTextColor = '#F39200';
      break;

    default:
      situationTagColor = 'white';
      situationTextColor = 'white';
  }

  function handleSeeProjectClick() {
    history.push(`/designer/projects/${project.id}`);
  }

  return (
    <Container>
      <Image src={project.images[0] ? project.images[0].url : ''} alt="" />
      <div>
        <h2>{project.name}</h2>
        <Situation tagColor={situationTagColor} textColor={situationTextColor}>
          <p>{project.situation}</p>
        </Situation>

        <CreatedAt>{`Criado em ${project.createdAt}`}</CreatedAt>
        <SeeProject onClick={handleSeeProjectClick}>Ver projeto</SeeProject>
      </div>
    </Container>
  );
};

DesignerProjectCard.propTypes = propTypes;

export default DesignerProjectCard;
