import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;

  div {
    margin-left: 4rem;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    margin-top: 1rem;

    div {
      margin: 0 2rem;
    }
  }
`;

const WelcomeImage = styled.img`
  width: 60rem;
  border-radius: 3rem;
  object-fit: cover;

  @media (max-width: 800px) {
    width: auto;
    margin: 2rem;
    border-radius: 1.5rem;
  }
`;

const Headline = styled.p`
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 3.2rem;
  max-width: 35rem;

  strong {
    font-weight: 900;
  }

  @media (max-width: 800px) {
    font-size: 3rem;
    max-width: none;
    text-align: center;
  }
`;

const Text = styled.p`
  color: #9a9a9a;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

export { WelcomeImage, Headline, Text, Container };
