import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';

const Container = styled.div`
  width: 100%;

  h1 {
    text-align: center;
    max-width: 45rem;
    margin: 7rem auto 5rem auto;
  }
`;

const ProjectsContainer = styled(Scroll)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 5px;

  > div {
    margin: 0 1rem;
    min-width: 30rem;
    max-width: 36rem;
    display: inline-block;
  }

  p {
    white-space: normal;
  }
`;

export { Container, ProjectsContainer };
