import { Switch } from 'react-router-dom';

import Route from './Route';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Projects from '../pages/Projects';
import ProjectInfo from '../pages/ProjectInfo';
import SuccessPurchase from '../pages/SuccessPurchase';
import UserProfile from '../pages/UserProfile';
import UserWallet from '../pages/UserWallet';
import UserProjects from '../pages/UserProjects';
import DesignerProjects from '../pages/DesignerProjects';
import NewProject from '../pages/NewProject';
import DesignerWallet from '../pages/DesignerWallet';
import DesignerSales from '../pages/DesignerSales';
import DesignerProjectInfo from '../pages/DesignerProjectInfo';
import EditProject from '../pages/EditProject';
import FreeProjects from '../pages/FreeProjects';
import BecomeDesigner from '../pages/BecomeDesigner';
import ProjectsByDesigner from '../pages/ProjectsByDesigner';
import UserCourses from '../pages/UserCourses';
import IndicateFriend from '../pages/IndicateFriend';
import Register from '../pages/Register';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" component={Login} loginRoute />
      <Route path="/projects" exact component={Projects} />
      <Route path="/become-designer" component={BecomeDesigner} />
      <Route path="/free-projects" component={FreeProjects} />
      <Route path="/projects/:id" component={ProjectInfo} />
      <Route path="/success-purchase" component={SuccessPurchase} />
      <Route path="/register" component={Register} />
      <Route
        path="/cutter/indicate-a-friend"
        component={IndicateFriend}
        privateRoute="cutter"
      />
      <Route
        path="/designer-projects/:designerId"
        component={ProjectsByDesigner}
      />
      <Route
        path="/cutter/wallet"
        component={UserWallet}
        privateRoute="cutter"
      />
      <Route
        path="/cutter/projects"
        component={UserProjects}
        privateRoute="cutter"
      />
      <Route
        path="/cutter/courses"
        component={UserCourses}
        privateRoute="cutter"
      />
      <Route
        path="/cutter/profile"
        component={UserProfile}
        privateRoute="cutter"
      />
      <Route
        path="/designer/sales"
        component={DesignerSales}
        privateRoute="designer"
      />
      <Route
        path="/designer/buys"
        component={UserProjects}
        privateRoute="designer"
      />
      <Route
        path="/designer/wallet"
        component={UserWallet}
        privateRoute="designer"
      />
      <Route
        path="/designer/remuneration"
        component={DesignerWallet}
        privateRoute="designer"
      />
      <Route
        path="/designer/profile"
        component={UserProfile}
        privateRoute="designer"
      />
      <Route
        path="/designer/projects"
        component={DesignerProjects}
        privateRoute="designer"
        exact
      />
      <Route
        path="/designer/new-project"
        component={NewProject}
        privateRoute="designer"
      />
      <Route
        path="/designer/projects/:id"
        component={DesignerProjectInfo}
        privateRoute="designer"
        exact
      />
      <Route
        path="/designer/projects/:id/edit"
        component={EditProject}
        privateRoute="designer"
      />
      <Route
        path="/designer/indicate-a-friend"
        component={IndicateFriend}
        privateRoute="designer"
      />
    </Switch>
  );
}
