import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  :focus-within {
    > label {
      color: var(--accent-color);
    }
  }
`;

const Label = styled.label`
  background-color: white;
  transform: translateY(10px);
  align-self: flex-start;
  padding: 0 10px 0 8px;
  margin-left: 10px;
  color: var(--primary-color);
  font-weight: 500;
  z-index: 1;
`;

const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    minHeight: '5rem',
    height: '5rem',
    boxShadow: 'none',
    border: isFocused
      ? '1px solid var(--accent-color) !important'
      : '1px solid var(--primary-color)',
    borderRadius: '8px',
    ':hover': {
      border: '1px solid var(--primary-color)',
      cursor: 'text',
    },
  }),
  container: styles => ({
    ...styles,
    minHeight: '5rem',
    height: '5rem',
    backgroundColor: 'white',
    ':hover': {},
  }),
  option: styles => ({
    ...styles,
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'rgba(243, 146, 00, 0.12)',
    fontSize: '1.6rem',
    padding: '0.2rem 0.5rem',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '5rem',
    height: '5rem',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'var(--accent-color)',
  }),
  menu: styles => ({
    ...styles,
    zIndex: 2,
  }),
  input: styles => ({
    ...styles,
    marginLeft: '0.8rem',
  }),
  clearIndicator: styles => ({
    ...styles,
    cursor: 'pointer',
  }),
  placeholder: styles => ({
    ...styles,
    marginTop: '0.6rem',
    marginLeft: '0.8rem',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    cursor: 'pointer',
  }),
  singleValue: styles => ({
    ...styles,
    marginTop: '5px',
    marginLeft: '8px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'var(--accent-color)',
    ':hover': {
      color: 'red',
      cursor: 'pointer',
    },
  }),
};

export { SelectContainer, Label, customStyles };
