import styled from 'styled-components';

const Container = styled.div`
  margin-top: 5rem;

  .container {
    display: flex;
    list-style: none;
  }

  .item {
    :hover {
      background-color: #f5f5f5;
    }
  }

  .link {
    padding: 0.5rem 1rem;
    border-radius: 7px;
    font-weight: 700;
    cursor: pointer;
  }

  .active {
    background-color: var(--accent-color);
    color: white;
  }
`;

export default Container;
