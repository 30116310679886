export default value => {
  if (value === null || value === undefined) {
    return null;
  }

  return (
    new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value)
      // Remove white space after sYmbol
      .replace(/\s/g, '')
  );
};
