import { useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';

import api from '../../services/api';
import Button from '../Button';
import Input from '../Input';
import Notification from '../Notification';

import { Headline, modalStyles, ButtonsContainer } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
};

function ReportModal({ isOpen, onClose, projectId }) {
  const formRef = useRef();

  async function handleSubmitForm(data, { reset: resetForm }) {
    try {
      await api.post('/contact_form', { ...data, projectId, type: 'REPORT' });
      onClose();
      Notification({
        message: 'Denúncia enviada com sucesso',
        type: Notification.types.SUCCESS,
      });
      resetForm();
    } catch (err) {
      if (err.response && err.response.data.errors) {
        formRef.current.setErrors(err.response.data.errors);
        return;
      }

      Notification({
        message: 'Erro ao formulário',
        type: Notification.types.ERROR,
      });
      onClose();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Denunciar projeto"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Form ref={formRef} onSubmit={handleSubmitForm}>
        <Headline>Denunciar projeto</Headline>
        <Input name="message" label="Motivo da denúncia" multiline />
        <Input name="name" label="Nome" />
        <Input name="phone" label="Telefone" />
        <Input name="email" label="Email" />

        <ButtonsContainer>
          <Button text="Cancelar" onClick={onClose} />
          <Button text="Confirmar" primary type="submit" />
        </ButtonsContainer>
      </Form>
    </Modal>
  );
}

ReportModal.propTypes = propTypes;

export default ReportModal;
