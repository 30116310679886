import { useEffect, useState } from 'react';

import FormatCurrency from '../../utils/FormatCurrency';
import Paginator from '../../components/Paginator';
import ProjectCard from '../../components/ProjectCard';
import Notification from '../../components/Notification';
import NoProjectsFound from '../../components/NoProjectsFound';
import ProjectsFilters from '../../components/ProjectsFilters';
import FeaturedProjects from '../../components/FeaturedProjects';
import api from '../../services/api';

import {
  Container,
  ProjectsList,
  ProjectsContainer,
  ProjectFiltersContainer,
  FilterIcon,
} from './styles';
import { useFilters } from '../../hooks/projectFilters';

const Projects = () => {
  const limit = 12;
  const [projects, setProjects] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const {
    currentPage,
    setCurrentPage,
    selectedMaterials,
    selectedCategories,
    premium,
    searchString,
  } = useFilters();

  function formatAndSetProjects({ projects: projs, count }) {
    const formattedProjects = projs.map(p => ({
      ...p,
      price: FormatCurrency(p.price),
      previousPrice: FormatCurrency(p.previousPrice),
    }));
    setProjects(formattedProjects);
    setPagesCount(Math.ceil(count / limit));
  }

  async function getProjects() {
    const filters = {
      categories: selectedCategories,
      materials: selectedMaterials,
      premium,
      page: currentPage,
      limit,
      searchString,
    };
    try {
      setLoading(true);
      const { data } = await api.get('/projects', {
        params: filters,
      });
      formatAndSetProjects(data);
    } catch (err) {
      Notification({
        message: 'Erro ao buscar projetos',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProjects();
  }, [
    selectedMaterials,
    selectedCategories,
    premium,
    currentPage,
    searchString,
  ]);

  async function handlePageChange(selectedPage) {
    document.getElementById('scroller').scroll(0, 0);
    setCurrentPage(selectedPage);
  }

  async function handleIconFilterClick() {
    setShowFilters(!showFilters);
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <FilterIcon size={32} onClick={handleIconFilterClick} />
        <ProjectFiltersContainer isOpen={showFilters}>
          <ProjectsFilters />
        </ProjectFiltersContainer>
        <ProjectsContainer>
          {!isLoading && projects.length === 0 ? (
            <NoProjectsFound text="Nenhum projeto encontrado" />
          ) : (
            <ProjectsList>
              {projects.map(p => (
                <ProjectCard project={p} key={p.id} />
              ))}
            </ProjectsList>
          )}
          {pagesCount > 0 && (
            <Paginator
              onPageChange={handlePageChange}
              pagesCount={pagesCount}
              initialPage={currentPage}
            />
          )}
        </ProjectsContainer>
      </Container>
      <FeaturedProjects title="Projetos destaque" />
    </>
  );
};

export default Projects;
