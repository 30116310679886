import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;

  @media (max-width: 800px) {
    :not(:last-child) {
      margin-bottom: 5rem;
    }
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: var(--primary-color);
  height: 9rem;
  width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.label`
  font-weight: 700;
  margin-top: 1rem;
`;

export { Circle, Text, Container };
