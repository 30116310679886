import axios from 'axios';

const api = axios.create({ baseURL: 'https://brasilapi.com.br/api' });

const getBankName = async bankCode => {
  if (bankCode === '') return '';

  try {
    const { data } = await api.get(`/banks/v1/${bankCode}`);
    return data.name;
  } catch (err) {
    return 'Banco não encontrado';
  }
};

export { api, getBankName };
