import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  align-self: center;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
`;

export default Container;
