import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import BoughtProjectCard from '../../components/BoughtProjectCard';
import NoBuyFound from '../../components/NoBuyFound';
import Notification from '../../components/Notification';

import { Container, ProjectsList } from './styles';

const UserProjects = () => {
  const { loading: authLoading } = useAuth();
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get('/bought_projects');
          setProjects(data);
        } catch (err) {
          Notification({
            message: 'Erro ao buscar projetos',
            type: Notification.types.ERROR,
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authLoading]);

  function renderProjectsList() {
    if (projects.length === 0) {
      return <NoBuyFound text="Você ainda não comprou nenhum projeto" />;
    }

    return (
      <ProjectsList>
        {projects.map(p => (
          <BoughtProjectCard
            key={p.id}
            project={{ imageUrl: p.images[0].url, ...p }}
          />
        ))}
      </ProjectsList>
    );
  }

  return (
    <Container>
      <h1>Meus projetos</h1>
      {isLoading ? <div className="loader" /> : renderProjectsList()}
    </Container>
  );
};

export default UserProjects;
