import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const HalfContainer = styled.div`
  width: 48%;
`;

const PicturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MainPicture = styled.img`
  width: 100%;
  height: 40rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  object-fit: cover;
`;

const Picture = styled.img`
  width: 24%;
  height: 12rem;
  border-radius: 20px;
  object-fit: cover;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  :not(:last-child) {
    margin-right: 1rem;
  }
`;

const Description = styled.p`
  color: #9a9a9a;
  margin-top: 3rem;
  margin-bottom: 5rem;
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  white-space: nowrap;
`;

const Category = styled.div`
  background: rgba(247, 182, 0, 0.12);
  border-radius: 6px;
  padding: 0.4rem 1.2rem;
  margin: 0.2rem 0;

  :not(:last-child) {
    margin-right: 1rem;
  }

  p {
    color: var(--accent-color);
  }
`;

const Price = styled.p`
  color: var(--accent-color);
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

const PreviousPrice = styled.p`
  color: #a9a9a9;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: line-through;
`;

const ScrollContainer = styled(Scroll)`
  width: 100%;
  white-space: nowrap;
`;

const ButtonsContainer = styled.div`
  button {
    margin-top: 5px;
  }
`;

export {
  Container,
  HalfContainer,
  PicturesContainer,
  MainPicture,
  Picture,
  Description,
  Categories,
  Category,
  PreviousPrice,
  Price,
  ScrollContainer,
  ButtonsContainer,
};
