import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 500px) {
    flex-direction: column;

    h1 {
      margin-bottom: 2rem;
    }
  }
`;

const ProjectsList = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 2rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

const Select = styled.select`
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: var(--font-color);
  border-radius: 8px;
  height: 5rem;
  min-width: 20rem;
  margin-top: 0.5rem;
  padding: 0 1rem;
`;

const SelectLabel = styled.p`
  font-weight: 500;
`;

export { Container, Header, ProjectsList, Select, SelectLabel };
