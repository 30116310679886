import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  form p {
    font-weight: 700;
    margin-top: 2rem;
  }

  h1 {
    margin-bottom: 3rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  div:not(:last-child) {
    margin-right: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2.5rem;

  @media (max-width: 800px) {
    button {
      width: 100%;
    }
  }
`;

export { Container, InputContainer, ButtonsContainer };
