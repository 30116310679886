import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';

const ScrollContainer = styled(Scroll)`
  white-space: nowrap;
`;

const NoReviews = styled.p`
  font-size: 2.2rem;
  align-self: center;
  margin-left: 1rem;
  text-align: center;
`;

export { ScrollContainer, NoReviews };
