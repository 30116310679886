import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoIosMove, IoMdCloseCircle } from 'react-icons/io';

// import { validateImageDimensions } from '../../utils/Image';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Input from '../../components/Input';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import InputCheckBox from '../../components/InputCheckBox';
import DraggableContainer from '../../components/DraggableContainer';

import {
  Container,
  InputContainer,
  AddFileInput,
  AddImageButton,
  Image as ProjectImage,
  ImageContainer,
  ImagesContainer,
  InputSmall,
} from './styles';

const EditProject = () => {
  const { loading: authLoading } = useAuth();
  const { id } = useParams();
  const inputUploadImageRef = useRef(null);
  const inputUploadProjectFileRef = useRef(null);
  const inputUploadProjectPdfRef = useRef(null);
  const formRef = useRef(null);

  const [isDesignerPremium, setDesignerPremium] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [excludedImages, setExcludedImages] = useState([]);

  const [file, setFile] = useState('');
  const [isNewFile, setNewFile] = useState(false);
  const [pdf, setPdf] = useState('');
  const [isNewPdf, setNewPdf] = useState(false);
  const [windowWidth, setWindoWidth] = useState(0);

  const [materialsOptions, setMaterialsOptions] = useState('');
  const [categoriesOptions, setCategoriesOptions] = useState('');
  const [projectSituation, setProjectSituation] = useState('');

  const [designerProfit, setDesignerProfit] = useState(0);
  const [isLoadingSaleTax, setLoadingSaleTax] = useState(true);
  const [saleTax, setSaleTax] = useState(0);

  function formatToSelectOption(arr) {
    return arr.map(i => ({ label: i.name, value: i.id }));
  }

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/project_categories');
      setCategoriesOptions(formatToSelectOption(data));
    })();

    (async () => {
      const { data } = await api.get('/materials');
      setMaterialsOptions(formatToSelectOption(data));
    })();

    function handleResize() {
      setWindoWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function calculateDesignerProfit(price, tax) {
    const discounted = tax ? +price * (tax / 100) : +price * (saleTax / 100);
    const profit = +price - discounted;
    return profit.toFixed(2);
  }

  function handleProjectPriceInputChange(e) {
    if (Number.isNaN(e.target.value)) {
      setDesignerProfit(0);
      return;
    }

    const profit = calculateDesignerProfit(e.target.value);
    setDesignerProfit(profit);
  }

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          setLoading(true);

          const response = await api.get('/sale_tax');
          setSaleTax(response.data.value);
          const tax = response.data.value;
          setLoadingSaleTax(false);

          const { data } = await api.get(`/project/${id}`);
          formRef.current.setData({
            ...data,
            categories: formatToSelectOption(data.categories),
            materials: formatToSelectOption(data.materials),
          });
          setProjectSituation(data.situation);
          setImages(data.images);
          setFile(data.projectFile || '');
          setPdf(data.projectPdf || '');
          setDesignerProfit(calculateDesignerProfit(data.price, tax));
          // This field is not filled when setData() is called
          formRef.current.setFieldValue('reproofReason', data.reproofReason);
        } catch (err) {
          Notification({
            type: Notification.types.ERROR,
            message: 'Erro ao buscar dados do projeto',
          });
        } finally {
          setLoading(false);
        }
      })();

      (async () => {
        const { data } = await api.get('/user');
        setDesignerPremium(data.designerPremium);
      })();
    }
  }, [authLoading]);

  function handleUploadImageButtonClick() {
    inputUploadImageRef.current.click();
  }

  function handleInputFileChange(e) {
    if (e.target.files) {
      setNewFile(true);
      setFile(e.target.files[0]);
    }
  }

  function handleUploadProjectFileButtonClick(e) {
    e.preventDefault();
    inputUploadProjectFileRef.current.click();
  }

  async function validateImages(files) {
    const maxSize = 4 * 1024 * 1024; // 4MB

    const validatedImages = await Promise.all(
      files.map(async f => {
        const validDimensions = true; // await validateImageDimensions(f, 500, 500);
        const validSize = f.size < maxSize;

        return { file: f, validDimensions, validSize };
      })
    );

    return validatedImages;
  }

  async function filterImages(files) {
    const validatedImages = await validateImages(files);

    const filteredImages = validatedImages.filter(f => {
      if (!f.validSize) {
        Notification({
          message: 'Tamanho máximo permitido é de 4MB',
          title: 'Arquivo muito grande',
          type: Notification.types.ERROR,
        });
        return false;
      }

      /* if (!f.validDimensions) {
        Notification({
          message: 'A imagem precisa ter 500px de largura por 500px de altura',
          title: 'Dimensões incorretas',
          type: Notification.types.ERROR,
        });
        return false;
      } */

      return true;
    });

    return filteredImages.map(f => f.file);
  }

  async function handleImageInputChange(e) {
    const { files } = e.target;
    if (files) {
      const filteredImages = await filterImages([...files]);
      setAddedImages([...addedImages, ...filteredImages]);

      const imagesUrl = filteredImages.map(f => ({
        url: window.URL.createObjectURL(f),
        name: f.name,
      }));
      setImages([...images, ...imagesUrl]);
    }
  }

  function validateForm(data) {
    if (!data.categories || data.categories.length === 0) {
      formRef.current.setFieldError(
        'categories',
        'Selecione pelo menos uma categoria'
      );
      return false;
    }

    if (!data.materials || data.materials.length === 0) {
      formRef.current.setFieldError(
        'materials',
        'Selecione pelo menos um material'
      );
      return false;
    }

    if (images.length === 0) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione pelo menos uma imagem',
      });
      return false;
    }

    if (!file) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione o arquivo do projeto',
      });

      return false;
    }

    return true;
  }

  function cleanForm() {
    setAddedImages([]);
    setExcludedImages([]);
  }

  async function editProject(data) {
    try {
      setLoading(true);
      formRef.current.setErrors({});

      data.featuredImage = images[0].name;
      const formData = new FormData();
      formData.append('project', JSON.stringify(data));

      if (isNewFile) {
        formData.append('projectFile', file);
      }

      if (isNewPdf) {
        formData.append('projectPdf', pdf);
      }

      if (addedImages.length > 0) {
        addedImages.forEach(i => formData.append('projectImages', i));
      }

      if (excludedImages.length > 0) {
        await Promise.all(
          excludedImages.map(imageId =>
            api.delete(`/project/${id}/image/${imageId}`)
          )
        );
      }

      await api.put(`/project/${id}`, formData);

      Notification({
        type: Notification.types.SUCCESS,
        message: 'Projeto alterado com sucesso',
      });
      cleanForm();
    } catch (err) {
      if (err.response && err.response.data.errors) {
        formRef.current.setErrors(err.response.data.errors);
        return;
      }

      const error = err.response
        ? err.response.data.error
        : 'Erro ao enviar formulário';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleFormSubmit(data) {
    formRef.current.setErrors({});
    if (validateForm(data)) {
      const materials = data.materials.map(m => ({ id: m.value }));
      const categories = data.categories.map(m => ({ id: m.value }));

      editProject({ ...data, materials, categories });
    }
  }

  function handleDeleteImageClick(i) {
    setExcludedImages([...excludedImages, i.id]);
    const filteredImages = images.filter(image => image.url !== i.url);
    setImages(filteredImages);
  }

  function handleUploadProjectPdfButtonClick(e) {
    e.preventDefault();
    inputUploadProjectPdfRef.current.click();
  }

  function handleInputPdfChange(e) {
    if (e.target.files) {
      setNewPdf(true);
      setPdf(e.target.files[0]);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <h1>Editar projeto</h1>
        <Form ref={formRef} onSubmit={data => handleFormSubmit(data)}>
          <Input name="name" label="Nome do projeto" />
          <Input
            type="text"
            name="shortDescription"
            label="Descrição curta (Máx 150 caracteres)"
            maxLength="150"
          />
          <ImagesContainer vertical={false} ignoreElements=".drag">
            <div>
              <AddFileInput
                ref={inputUploadImageRef}
                type="file"
                multiple="multiple"
                onChange={handleImageInputChange}
              />
              <AddImageButton
                title="Adicionar imagem"
                type="button"
                onClick={handleUploadImageButtonClick}
              >
                <AiOutlinePlus size={50} color="#BDBDBD" />
              </AddImageButton>
              <p className="featured-image">Imagem destaque</p>
            </div>
            <DraggableContainer items={images} setItems={setImages}>
              {images &&
                images.map(i => (
                  <ImageContainer key={i.id}>
                    <ProjectImage
                      src={i.url}
                      alt="Imagem do projeto"
                      draggable="false"
                    />
                    <IoIosMove size={24} className="drag" color="#00ADEF" />
                    <IoMdCloseCircle
                      size={20}
                      class="delete"
                      color="#00ADEF"
                      onClick={() => handleDeleteImageClick(i)}
                    />
                  </ImageContainer>
                ))}
            </DraggableContainer>
          </ImagesContainer>
          <InputSmall>
            Tamanho máximo: 5MB. Formatos aceitos: PNG, JPG e JPEG.
          </InputSmall>
          <Input
            name="videoUrl"
            label="URL do vídeo de demonstração do projeto"
          />
          <Input
            step=".01"
            type="number"
            name="price"
            label="Preço"
            width={windowWidth > 800 ? 30 : 100}
            onChange={handleProjectPriceInputChange}
          />
          {!isLoadingSaleTax && (
            <InputSmall>
              Você receberá R${designerProfit} por venda. (Taxa de {saleTax}%)
            </InputSmall>
          )}
          <InputSelect
            name="categories"
            options={categoriesOptions}
            label="Categorias"
            placeholder="Selecione as categorias"
            noOptionsMessage="Sem categorias"
            isMulti
            isLoading={categoriesOptions === null}
          />
          <InputSelect
            name="materials"
            options={materialsOptions}
            label="Materiais"
            placeholder="Selecione os materiais"
            noOptionsMessage="Sem materiais"
            isMulti
            isLoading={materialsOptions === null}
          />

          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectFileRef}
              type="file"
              onChange={handleInputFileChange}
            />
            <Input
              name="filename"
              label="Arquivo do projeto"
              disabled
              value={file.name}
            />
            <Button
              text="Upload"
              onClick={handleUploadProjectFileButtonClick}
            />
          </InputContainer>
          <InputSmall>Tamanho máximo: 2MB. Formato aceito: SVG</InputSmall>
          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectPdfRef}
              type="file"
              onChange={handleInputPdfChange}
            />
            <Input
              name="filename"
              label="Arquivo PDF"
              disabled
              value={pdf.name}
            />
            <Button text="Upload" onClick={handleUploadProjectPdfButtonClick} />
          </InputContainer>
          <InputSmall>Tamanho máximo: 2MB.</InputSmall>

          <Input name="tutorialUrl" label="URL do vídeo tutorial" />
          <Input name="longDescription" label="Descrição longa" multiline />
          <Input name="situation" label="Situação do projeto" disabled />
          {projectSituation === 'Verificar' && (
            <Input
              name="reproofReason"
              label="O que precisa ser verificado"
              multiline
              disabled
            />
          )}
          {isDesignerPremium && (
            <InputCheckBox type="checkbox" name="premium" label="Projeto PRO" />
          )}
          <Button type="submit" text="Salvar alterações" primary />
        </Form>
      </Container>
    </>
  );
};

export default EditProject;
