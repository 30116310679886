import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import FormatCurrency from '../../utils/FormatCurrency';
import Button from '../../components/Button';
import TransactionsList from '../../components/TransactionsList';
import ModalWithInput from '../../components/ModalWithInput';
import Notification from '../../components/Notification';

import { Header, Balance, Headline, ButtonsContainer } from './styles';

const UserWallet = () => {
  const { loading: authLoading } = useAuth();
  const [userBalance, setUserBalance] = useState({});
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [voucherModalOpen, setVoucherModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  async function fetchWallet() {
    try {
      setLoading(true);
      const { data } = await api.get('/user_balance');
      setUserBalance({
        balance: FormatCurrency(data.balance),
        transactions: data.transactions.map(t => ({
          ...t,
          amount: FormatCurrency(t.amount),
        })),
      });
    } catch (err) {
      Notification({
        message: 'Erro ao buscar dados da carteira',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!authLoading) {
      fetchWallet();
    }
  }, [authLoading]);

  // Voucher modal
  function handleVoucherButtonClick() {
    setVoucherModalOpen(true);
  }

  function handleVoucherModalClose() {
    setVoucherModalOpen(false);
  }

  async function handleRequestVoucher(token) {
    try {
      setLoading(true);
      await api.post(`/voucher/${token}/use`);

      Notification({
        type: Notification.types.SUCCESS,
        message: 'Voucher utilizado com sucesso',
      });
      fetchWallet();
    } catch (err) {
      if (err.response) {
        Notification({
          type: Notification.types.ERROR,
          message: err.response.data.error,
        });
      } else {
        Notification({
          type: Notification.types.ERROR,
          message: 'Erro ao utilizar voucher',
        });
      }
    } finally {
      setLoading(false);
      setVoucherModalOpen(false);
    }
  }

  // Deposit modal
  function handleAddBalanceButtonClick() {
    setDepositModalOpen(true);
  }

  function handleDepositModalClose() {
    setDepositModalOpen(false);
  }

  async function handleRequestDeposit(amount) {
    try {
      const { data } = await api.post('/request_deposit', { amount });
      window.open(data.paymentLink);
      setDepositModalOpen(false);
    } catch (err) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Erro ao gerar link de pagamento',
      });
    }
  }

  function renderTransactions() {
    if (userBalance.transactions && userBalance.transactions.length > 0) {
      return (
        <>
          <Headline>Transações</Headline>
          <TransactionsList transactions={userBalance.transactions} />
        </>
      );
    }

    return <></>;
  }

  return (
    <>
      <ModalWithInput
        isOpen={depositModalOpen}
        onClose={handleDepositModalClose}
        onConfirm={handleRequestDeposit}
        title="Realizar depósito"
        text="Informe qual é o valor que você deseja adicionar a sua conta."
      />
      <ModalWithInput
        isOpen={voucherModalOpen}
        onClose={handleVoucherModalClose}
        onConfirm={handleRequestVoucher}
        title="Utilizar voucher"
        text="Digite o código para utilizar o voucher e adicionar o valor a sua carteira."
      />
      <div>
        <Header>
          <div>
            <p>Meu saldo</p>
            <Balance>{userBalance.balance}</Balance>
          </div>
          <ButtonsContainer>
            <Button
              text="Utilizar voucher"
              onClick={handleVoucherButtonClick}
            />
            <Button
              text="Adicionar saldo"
              primary
              onClick={handleAddBalanceButtonClick}
            />
          </ButtonsContainer>
        </Header>
        {isLoading ? <div className="loader" /> : renderTransactions()}
      </div>
    </>
  );
};

export default UserWallet;
