import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import FormatCurrency from '../../utils/FormatCurrency';
import ProjectCard from '../ProjectCard';

import { Container, ProjectsContainer } from './styles';

const propTypes = {
  currentProjectId: PropTypes.number,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  currentProjectId: 0,
};

const FeaturedProjects = ({ currentProjectId, title }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/featured_projects');

      const filteredProjects = data.filter(p => {
        // Don't show bought projects
        if (p.bought) {
          return false;
        }

        // Filters the project being shown
        if (p.id === +currentProjectId) {
          return false;
        }

        return true;
      });
      const formattedProjects = filteredProjects.map(p => ({
        ...p,
        price: FormatCurrency(p.price),
        previousPrice: FormatCurrency(p.previousPrice),
      }));
      setProjects(formattedProjects);
    })();
  }, []);

  return projects.length > 0 ? (
    <Container>
      <h1>{title}</h1>
      <ProjectsContainer vertical={false}>
        {projects &&
          projects.map(project => (
            <ProjectCard
              key={project.id}
              project={{ ...project, imageUrl: project.images[0].url }}
            />
          ))}
      </ProjectsContainer>
    </Container>
  ) : (
    <></>
  );
};

FeaturedProjects.propTypes = propTypes;
FeaturedProjects.defaultProps = defaultProps;

export default FeaturedProjects;
