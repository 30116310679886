import PropTypes from 'prop-types';
import { FiPlus, FiStar } from 'react-icons/fi';

import { StyledButton, ButtonText } from './styles';

const propTypes = {
  text: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  textBold: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  withIcon: PropTypes.oneOf(['start', 'plus']),
  color: PropTypes.string,
  type: PropTypes.string,
  minWidth: PropTypes.number,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  textBold: false,
  primary: false,
  withIcon: null,
  color: null,
  type: 'button',
  minWidth: 0,
  isDisabled: false,
};

const Button = ({
  text,
  primary,
  onClick,
  textBold,
  withIcon,
  color,
  type,
  minWidth,
  isDisabled,
  title,
}) => {
  function renderButtonIcon() {
    const iconColor = primary ? 'white' : '#F39200';

    switch (withIcon) {
      case 'star':
        return <FiStar size={22} color={iconColor} fill={iconColor} />;
      case 'plus':
        return <FiPlus size={22} color={iconColor} />;
      default:
        return <></>;
    }
  }

  return (
    <StyledButton
      type={type}
      primary={primary}
      color={color}
      onClick={onClick}
      minWidth={minWidth}
      disabled={isDisabled}
      title={title}
    >
      <ButtonText
        primary={primary}
        bold={textBold}
        withIcon={withIcon}
        color={color}
      >
        {text}
      </ButtonText>
      {withIcon && renderButtonIcon()}
    </StyledButton>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
