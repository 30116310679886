import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import { Headline, ButtonsContainer, Content, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ConfirmModal = ({ isOpen, onClose, onConfirm, title, text }) => {
  function handleConfirmButtonClick() {
    onConfirm();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Realizar depósito"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Content>
        <Headline>{title}</Headline>
        <p>{text}</p>
      </Content>
      <ButtonsContainer>
        <Button text="Cancelar" onClick={onClose} />
        <Button text="Confirmar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

ConfirmModal.propTypes = propTypes;

export default ConfirmModal;
