import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import ProjectCard from '../ProjectCard';
import Button from '../Button';
import FormatCurrency from '../../utils/FormatCurrency';
import { useFilters } from '../../hooks/projectFilters';

import { Container, ProjectsContainer, Headline } from './styles';

const ProjectsSection = () => {
  const history = useHistory();
  const { resetFilters } = useFilters();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/projects?limit=6');

      const formattedProjects = data.projects.map(p => ({
        ...p,
        price: FormatCurrency(p.price),
        previousPrice: FormatCurrency(p.previousPrice),
      }));
      setProjects(formattedProjects);
    })();
  }, []);

  function handleProjectsButtonClick() {
    history.push('/projects');
    resetFilters();
  }

  return (
    <Container>
      <Headline>
        <strong>Projetos</strong> para você
      </Headline>
      {!projects ? (
        <p>Carregando</p>
      ) : (
        <ProjectsContainer>
          {projects.map(p => (
            <ProjectCard project={p} key={p.id} />
          ))}
        </ProjectsContainer>
      )}

      <Button
        text="Ver todos os projetos"
        primary
        onClick={handleProjectsButtonClick}
      />
    </Container>
  );
};
export default ProjectsSection;
