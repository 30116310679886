import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4rem 0 4rem;

  @media (max-width: 1080px) {
    padding: 0 2rem 0 2rem;
  }
`;

const Main = styled.main`
  max-width: 1250px;
  align-self: center;
  margin: 4rem 0;
  width: 100%;
`;

export { Container, Main };
