import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';
import { Video, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

const VideoModal = ({ isOpen, onClose, videoUrl }) => {
  const [windowSize, setWindowSize] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Realizar depósito"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Video
        customWidth={windowSize.width * 0.6}
        customHeight={windowSize.height * 0.6}
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video tutorial"
      />
    </Modal>
  );
};

VideoModal.propTypes = propTypes;

export default VideoModal;
