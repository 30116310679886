import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Container, Image, Description } from './styles';

const propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
  }).isRequired,
};

const BoughtProjectCard = ({
  project: { imageUrl, name, shortDescription, id },
}) => {
  const history = useHistory();

  function handleCardClick() {
    history.push(`/projects/${id}`);
  }

  return (
    <Container onClick={handleCardClick}>
      <Image src={imageUrl} />
      <div>
        <h2>{name}</h2>
        <Description>{shortDescription}</Description>
      </div>
    </Container>
  );
};

BoughtProjectCard.propTypes = propTypes;

export default BoughtProjectCard;
