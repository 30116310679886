import styled from 'styled-components';

const CustomInput = styled.input`
  margin-right: 1rem;
`;

const Label = styled.label``;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export { CustomInput, Label, InputContainer };
