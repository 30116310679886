import { createContext, useState, useContext, useEffect } from 'react';

const FiltersContext = createContext({});

const FiltersProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [premium, setPremium] = useState(false);

  function resetFilters() {
    setCurrentPage(1);
    setSelectedCategories([]);
    setSelectedMaterials([]);
    setSearchString('');
    setPremium(false);
  }

  useEffect(() => {
    const jsonFilters = localStorage.getItem('@portal-due-it/filters');

    if (jsonFilters) {
      const filters = JSON.parse(jsonFilters);
      setCurrentPage(filters.currentPage);
      setSelectedCategories(filters.selectedCategories);
      setSelectedMaterials(filters.selectedMaterials);
      setSearchString(filters.searchString);
      setPremium(filters.premium);
    }
  }, []);

  useEffect(() => {
    const filters = {
      selectedCategories,
      selectedMaterials,
      currentPage,
      premium,
      searchString,
    };
    localStorage.setItem('@portal-due-it/filters', JSON.stringify(filters));
  }, [
    setCurrentPage,
    selectedMaterials,
    selectedCategories,
    searchString,
    premium,
    currentPage,
  ]);

  return (
    <FiltersContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        selectedMaterials,
        setSelectedMaterials,
        selectedCategories,
        setSelectedCategories,
        searchString,
        setSearchString,
        premium,
        setPremium,
        resetFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

function useFilters() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  return context;
}

export { FiltersProvider, useFilters };
