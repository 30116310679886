import { NotificationContainer } from 'react-notifications';
import { AuthProvider } from './auth';
import { FiltersProvider } from './projectFilters';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <NotificationContainer />
    <FiltersProvider>{children}</FiltersProvider>
  </AuthProvider>
);

export default AppProvider;
