import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { useState } from 'react';
import Button from '../Button';

import {
  Headline,
  ButtonsContainer,
  modalStyles,
  InputContainer,
  CheckBoxError,
  CheckBox,
} from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const NewProjectContractModal = ({ isOpen, onClose, onConfirm }) => {
  const [error, setError] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  function handleConfirmButtonClick() {
    setError('');

    if (!termsChecked) {
      setError('Você precisa concordar com os termos');
      return;
    }

    onConfirm();
  }

  function handleTermsCheckboxChange(e) {
    setTermsChecked(e.target.checked);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Contrato de novo projeto"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <div>
        <Headline>Termos</Headline>
        <p>
          Aqui vai os termos que o designer deve aceitar ao enviar um novo
          projeto ao portal.
        </p>
      </div>
      <InputContainer>
        <CheckBox
          id="terms"
          type="checkbox"
          checked={termsChecked}
          onChange={handleTermsCheckboxChange}
        />
        <label htmlFor="terms">
          Li e concordo com todos os termos descritos acima
        </label>
      </InputContainer>
      {error && <CheckBoxError>{error}</CheckBoxError>}
      <ButtonsContainer>
        <Button text="Cancelar" onClick={onClose} />
        <Button text="Confirmar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

NewProjectContractModal.propTypes = propTypes;

export default NewProjectContractModal;
