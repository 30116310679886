import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4rem 0 4rem;
`;

const Main = styled.main`
  width: 100%;
  margin-left: 5rem;

  @media (max-width: 800px) {
    margin-left: 0rem;
  }
`;

const MainContainer = styled.div`
  display: flex;
  max-width: 1250px;
  margin: 6rem 0 8rem 0;
  align-self: center;
  width: 100%;

  /* @media (max-width: 800px) {
    flex-direction: column;
  } */
`;

const MenuContainer = styled.div`
  @media (max-width: 800px) {
    margin-bottom: 5rem;
    display: none;
  }
`;

export { Container, Main, MainContainer, MenuContainer };
