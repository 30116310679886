import styled from 'styled-components';

const Container = styled.div``;

const DesignerInfo = styled.div`
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 3rem 7rem 3rem 3rem;
  margin: 1rem 0 8rem 0;
  display: inline-flex;
  align-items: center;

  > div {
    margin-left: 2rem;
  }
`;

const DesignerName = styled.p`
  color: #9a9a9a;
  font-weight: 700;
  font-size: 2rem;
`;

const TotalProjects = styled.small`
  color: #9a9a9a;
`;

const ProjectsContainer = styled.div`
  margin-bottom: 10rem;
`;

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-row-gap: 3rem;
  grid-column-gap: 1.5rem;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DesignerImage = styled.img`
  width: 5rem;
  border-radius: 50%;
`;

export {
  Container,
  DesignerInfo,
  DesignerName,
  TotalProjects,
  ProjectsContainer,
  ProjectsList,
  DesignerImage,
};
