import styled from 'styled-components';
import Button from '../Button';

const Headline = styled.h2`
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;

  button:not(:last-child) {
    margin-right: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const CancelButton = styled(Button)`
  border: 2px red solid !important;
`;

const modalStyles = {
  content: {
    padding: '40px 60px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
  },
};

export { Headline, ButtonsContainer, Content, modalStyles, CancelButton };
