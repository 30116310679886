import { GrFacebookOption, GrYoutube, GrInstagram } from 'react-icons/gr';

import LogoSvg from '../../assets/svg/logo-white.svg';
import VisaSvg from '../../assets/svg/visa.svg';
import MastercardSvg from '../../assets/svg/mastercard.svg';
import PaypalSvg from '../../assets/svg/paypal.svg';
import {
  Container,
  FooterDiv,
  Logo,
  Copyright,
  FooterLink,
  PaymentMethodsContainer,
  SafePaymentText,
  PaymentMethodSvg,
  SocialIconsContainer,
  FooterContent,
  FooterHeader,
  LinksContainer,
} from './styles';

const Footer = () => (
  <Container>
    <FooterHeader>
      <Logo src={LogoSvg} alt="Logo" />
      <SocialIconsContainer>
        <GrFacebookOption
          color="white"
          size={24}
          cursor="pointer"
          title="Facebook"
          onClick={() => window.open('https://pt-br.facebook.com/duelaser')}
        />
        <GrInstagram
          color="white"
          size={22}
          cursor="pointer"
          title="Instagram"
          onClick={() => window.open('https://www.instagram.com/duelaser')}
        />
        <GrYoutube
          color="white"
          size={26}
          cursor="pointer"
          title="YouTube"
          onClick={() => window.open('https://www.youtube.com/duelaser')}
        />
      </SocialIconsContainer>
    </FooterHeader>
    <FooterContent>
      <SafePaymentText>Pagamento Seguro</SafePaymentText>
      <FooterDiv>
        <Copyright>
          {new Date().getFullYear()} Due Laser • Todos os Direitos Reservados
        </Copyright>
        <LinksContainer>
          <FooterLink to="/">Termos de Serviço</FooterLink>
          <FooterLink to="/">Privacidade</FooterLink>
          <FooterLink
            to={{
              pathname: 'https://www.duelaser.com/contato',
            }}
            target="_blank"
          >
            Entre em Contato
          </FooterLink>
        </LinksContainer>
        <PaymentMethodsContainer>
          <FooterDiv>
            <PaymentMethodSvg src={VisaSvg} alt="Cartão Visa" />
            <PaymentMethodSvg src={MastercardSvg} alt="Cartão Mastercard" />
            <PaymentMethodSvg src={PaypalSvg} alt="Cartão Paypal" />
          </FooterDiv>
        </PaymentMethodsContainer>
      </FooterDiv>
    </FooterContent>
  </Container>
);

export default Footer;
