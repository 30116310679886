import PropTypes from 'prop-types';
import api from '../../services/api';

import ConfirmModal from '../ConfirmModal';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CancelProModal = ({ onClose, isOpen }) => {
  async function handleProModalConfirm() {
    try {
      await api.put('/cancel_plan');
      Notification({
        message: 'Assinatura cancelada com sucesso',
        type: Notification.types.ERROR,
      });
      onClose();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
      } else {
        Notification({
          message: 'Erro ao cancelar assinatura',
          type: Notification.types.ERROR,
        });
      }
    }
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleProModalConfirm}
      text={
        'Ao cancelar a assinatura Pro você perderá o acesso a todos os ' +
        'projetos Pro. Deseja cancelar a assinatura?'
      }
      title="Cancelar assinatura PRO"
    />
  );
};

CancelProModal.propTypes = propTypes;

export default CancelProModal;
