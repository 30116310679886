import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem 2rem;
`;

const Headline = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;

  @media (max-width: 800px) {
    flex-direction: column;

    button {
      margin-top: 2rem;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;

  > input {
    margin-right: 1rem;
  }

  > p {
    margin-right: 2rem;
    align-self: center;
  }
`;

const Error = styled.div`
  color: #ff0000;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
  },
};

export { Headline, Container, Footer, CheckboxContainer, modalStyles, Error };
