import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import { Container } from './styles';

const SuccessPurchase = () => {
  const history = useHistory();

  function handleHomeButtonClick() {
    history.push('/projects');
  }

  return (
    <Container>
      <FaCheck size={60} color="#F39200" />
      <h1>Compra efetuada com sucesso</h1>
      <p>
        Oba! Sua compra foi finalizada com sucesso e agora você já pode cortar o
        projeto comprado utilizando o programa Due Studio. Obrigado
      </p>
      <Button
        text="Voltar para página explorar"
        onClick={handleHomeButtonClick}
      />
    </Container>
  );
};

export default SuccessPurchase;
