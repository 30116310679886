import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';

import api from '../../services/api';
import Notification from '../../components/Notification';
import Button from '../../components/Button';
import Input from '../../components/Input';

import { Container, Text } from './styles';
import InputMask from '../../components/InputMask';

const Register = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const token = query.get('t');
      if (!token) return;

      setLoading(true);
      try {
        await api.get(`/indication/${token}`);
      } catch (err) {
        if (err.response) {
          Notification({
            message: err.response.data.error,
            type: Notification.types.ERROR,
          });
        } else {
          Notification({
            message: 'Token inválido',
            type: Notification.types.ERROR,
          });
        }

        history.push('/');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  async function handleSubmitForm(data) {
    try {
      setLoading(true);
      data.indicationToken = query.get('t');
      await api.post(`/users`, data);
      Notification({
        message: 'Usuário criado com sucesso! Verique seu email',
        type: Notification.types.SUCCESS,
      });
      history.push('/');
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          formRef.current.setErrors(err.response.data.errors);
          return;
        }

        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
        return;
      }

      Notification({
        message: 'Erro ao realizar cadastro',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <Text>Seja bem vindo ao portal Due It!</Text>
        <Text>Faça seu cadastro.</Text>
        <Form
          initialData={{ indicationToken: query.get('t') }}
          ref={formRef}
          onSubmit={handleSubmitForm}
        >
          <Input type="email" name="email" label="E-mail" />
          <Input type="text" name="name" label="Nome" />
          <InputMask name="cpf" label="CPF" mask="999.999.999-99" />
          <Input type="password" name="password" label="Senha" />
          <Input
            type="password"
            name="confirmPassword"
            label="Confirmar senha"
          />
          <Button width={100} primary text="Cadastrar" type="submit" />
        </Form>
      </Container>
    </>
  );
};

export default Register;
