import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-top: 1rem;
    align-self: flex-start;
  }

  form {
    max-width: 500px;
  }
`;

const Headline = styled.h2`
  font-size: 2rem;
  margin: 2rem 0;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const Text = styled.p`
  display: inline-block;
`;

const CopyButton = styled.button`
  border: none;
  border-radius: 8px;
  background: #f2f2f2;
  margin-left: 1rem;
  padding: 0 10px;
  height: 28px;
  user-select: none;
`;

const IndicationItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  border: 1px #a9a9a9 solid;
  border-radius: 8px;
  padding: 1rem 1.5rem;

  p {
    padding: 0.3rem 0;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export {
  Container,
  Text,
  modalStyles,
  ButtonsContainer,
  Headline,
  CopyButton,
  IndicationItem,
};
