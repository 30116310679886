import { useCallback } from 'react';
import {
  FiCheckCircle,
  FiShoppingBag,
  FiUploadCloud,
  FiCodesandbox,
  FiTruck,
} from 'react-icons/fi';

import HowWorksItem from '../HowWorksItem';
import { ItemsContainer, Line, Title, Container } from './styles';

const HowWorksSection = () => {
  const items = [
    { text: 'Escolha seu projeto', Icon: FiCheckCircle },
    { text: 'Compre seu projeto', Icon: FiShoppingBag },
    { text: 'Envie para sua máquina', Icon: FiUploadCloud },
    { text: 'Corte e embale', Icon: FiCodesandbox },
    { text: 'Envie a seus clientes', Icon: FiTruck },
  ];

  const renderItems = useCallback(
    () =>
      items.map(({ Icon, text }) => (
        <HowWorksItem key={text} Icon={Icon} text={text} />
      )),
    []
  );

  return (
    <Container>
      <Title>
        Como <strong>funciona</strong>
      </Title>
      <ItemsContainer>{renderItems()}</ItemsContainer>
      <Line />
    </Container>
  );
};

export default HowWorksSection;
