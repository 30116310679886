import styled from 'styled-components';

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;

  > button:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const Input = styled.textarea`
  border: 1px solid #9a9a9a;
  padding-top: 14px;
  border-radius: 8px;
  width: 100%;
  height: 15rem;
  margin-top: 1.6rem;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const StarsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  svg:not(:first-child) {
    margin-left: 1rem;
  }
`;

const Error = styled.span`
  color: #f00;
  font-weight: 500;
  align-self: center;
  margin-left: 1rem;
`;

export {
  Headline,
  Input,
  ButtonsContainer,
  modalStyles,
  Error,
  StarsContainer,
};
