import styled from 'styled-components';

const Container = styled.div``;

const ProjectsList = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2.5rem;
  }
`;

export { Container, ProjectsList };
