import PropTypes from 'prop-types';

import EmptyCartSvg from '../../assets/svg/empty-cart.svg';
import { Container, Image, Text } from './styles';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const NoBuyFound = ({ text }) => (
  <Container>
    <Text>{text}</Text>
    <Image src={EmptyCartSvg} alt="Não encontrado" />
  </Container>
);

NoBuyFound.propTypes = propTypes;

export default NoBuyFound;
