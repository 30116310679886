import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import NoProjectsFound from '../../components/NoProjectsFound';
import ProjectCard from '../../components/ProjectCard';
import Notification from '../../components/Notification';
import api from '../../services/api';

import {
  Container,
  DesignerInfo,
  DesignerName,
  TotalProjects,
  ProjectsContainer,
  ProjectsList,
  DesignerImage,
} from './styles';
import FormatCurrency from '../../utils/FormatCurrency';

const ProjectsByDesigner = () => {
  const { designerId } = useParams();
  const location = useLocation();

  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/projects_by_designer/${designerId}`);
        const formattedProjects = data.projects.map(p => ({
          ...p,
          price: FormatCurrency(p.price),
          previousPrice: FormatCurrency(p.previousPrice),
        }));
        setProjects(formattedProjects);
        setTotalProjects(data.count);
      } catch (err) {
        const error = err.response
          ? err.response.data.error
          : 'Erro ao buscar projetos';
        Notification({ message: error, type: Notification.types.ERROR });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Container>
      {isLoading ? (
        <div className="loader" />
      ) : (
        <>
          <DesignerInfo>
            {location.state && location.state.designer.image ? (
              <DesignerImage
                src={location.state.designer.image.url}
                alt="Imagem de usuário"
              />
            ) : (
              <FaUserCircle color="#9a9a9a" size={40} />
            )}
            <div>
              <DesignerName>{location.state.designer.name}</DesignerName>
              <TotalProjects>{`${totalProjects} Projetos`}</TotalProjects>
            </div>
          </DesignerInfo>
          <ProjectsContainer>
            {!isLoading && projects.length === 0 ? (
              <NoProjectsFound text="Nenhum projeto encontrado" />
            ) : (
              <ProjectsList>
                {projects.map(p => (
                  <ProjectCard project={p} key={p.id} />
                ))}
              </ProjectsList>
            )}
          </ProjectsContainer>
        </>
      )}
    </Container>
  );
};

export default ProjectsByDesigner;
