import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
  max-height: 20rem;

  :hover {
    background-color: #fafafa;
    opacity: 0.95;
    transition: background-color 0.2s;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin-top: 0.5rem;
  }
`;

const Situation = styled.div`
  border-radius: 6px;
  background-color: ${props => props.tagColor};
  margin: 1rem 0;
  align-self: flex-start;
  padding: 0.5rem 1.2rem;
  > p {
    color: ${props => props.textColor};
  }
`;

const Image = styled.img`
  border-radius: 20px;
  width: 50%;
  margin-right: 2rem;
  object-fit: cover;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.05);
    transition: transform 0.2s;
  }
`;

const CreatedAt = styled.p`
  color: #9a9a9a;
`;

const SeeProject = styled.p`
  color: #f39200;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 1rem;
  font-size: 1.6rem;
`;

export { Container, Image, CreatedAt, SeeProject, Situation };
