import { useState, useEffect } from 'react';
import { useFilters } from '../../hooks/projectFilters';

import api from '../../services/api';
import {
  FiltersContainer,
  Headline,
  Line,
  HeadlineContainer,
  Text,
  CheckboxContainer,
  Divider,
} from './styles';

const ProjectsFilters = () => {
  const {
    selectedCategories,
    setSelectedCategories,
    selectedMaterials,
    setSelectedMaterials,
    premium,
    setPremium,
  } = useFilters();

  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/project_categories');
      setCategories(data);
    })();
    (async () => {
      const { data } = await api.get('/materials');
      setMaterials(data);
    })();
  }, []);

  function handleMaterialCheckboxChange(e) {
    if (e.target.checked) {
      setSelectedMaterials([...selectedMaterials, e.target.value]);
    } else {
      setSelectedMaterials(selectedMaterials.filter(i => i !== e.target.value));
    }
  }

  function handleCategoryCheckboxChange(e) {
    if (e.target.checked) {
      setSelectedCategories([...selectedCategories, e.target.value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter(i => i !== e.target.value)
      );
    }
  }

  function handlePremiumCheckboxChange(e) {
    setPremium(e.target.checked);
  }

  return (
    <FiltersContainer>
      <HeadlineContainer>
        <Headline>Filtros</Headline>
        <Line />
      </HeadlineContainer>

      <Text>Categorias</Text>
      {categories.map(c => (
        <CheckboxContainer key={c.id}>
          <input
            id={c.name}
            type="checkbox"
            value={c.id}
            checked={!!selectedCategories.find(e => +e === c.id)}
            onChange={handleCategoryCheckboxChange}
          />
          <label htmlFor={c.name}>{c.name}</label>
        </CheckboxContainer>
      ))}
      <Divider />

      <Text>Materiais</Text>
      {materials.map(m => (
        <CheckboxContainer key={m.id}>
          <input
            id={m.name}
            type="checkbox"
            value={m.id}
            checked={!!selectedMaterials.find(e => +e === m.id)}
            onChange={handleMaterialCheckboxChange}
          />
          <label htmlFor={m.name}>{m.name}</label>
        </CheckboxContainer>
      ))}
      <Divider />

      <CheckboxContainer>
        <input
          id="premium"
          type="checkbox"
          value={premium}
          checked={premium}
          onChange={handlePremiumCheckboxChange}
        />
        <label htmlFor="premium">Pro</label>
      </CheckboxContainer>
    </FiltersContainer>
  );
};

export default ProjectsFilters;
