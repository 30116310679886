import PropTypes from 'prop-types';
import { FiShoppingBag, FiDollarSign, FiPackage, FiGift } from 'react-icons/fi';

import {
  Container,
  Description,
  Amount,
  DateText,
  IconContainer,
} from './styles';

const propTypes = {
  transaction: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['DEPOSIT', 'PLAN', 'PURCHASE', 'SALE']).isRequired,
  }).isRequired,
};

const TransactionItem = ({
  transaction: { amount, date, description, type },
}) => {
  function renderIcon() {
    switch (type) {
      case 'DEPOSIT':
      case 'SALE':
      case 'VOUCHER':
      case 'WITHDRAWAL':
      case 'PAYMENT':
        return <FiDollarSign color="#000" size={22} />;

      case 'PLAN':
        return <FiPackage color="#000" size={22} />;

      case 'PURCHASE':
        return <FiShoppingBag color="#000" size={22} />;

      case 'CREDIT_BY_INDICATION':
        return <FiGift color="#000" size={22} />;
      default:
        return <div />;
    }
  }

  function formatDate(d) {
    return new Date(d).toLocaleString('pt-BR');
  }

  return (
    <Container>
      <IconContainer>{renderIcon()}</IconContainer>
      <div>
        <Description>{description}</Description>
        <DateText>{formatDate(date)}</DateText>
      </div>
      <Amount>{amount}</Amount>
    </Container>
  );
};

TransactionItem.propTypes = propTypes;

export default TransactionItem;
