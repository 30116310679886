import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FiStar } from 'react-icons/fi';

import {
  Container,
  Image,
  Headline,
  Description,
  Price,
  Categories,
  Category,
  Author,
  InfoContainer,
  PriceContainer,
  PreviousPrice,
  PremiumStar,
  InnerContainer,
  RatingContainer,
  Rate,
  PremiumContainer,
  NameContainer,
} from './styles';

const propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    designerUser: PropTypes.shape({ name: PropTypes.string.isRequired })
      .isRequired,
    shortDescription: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    previousPrice: PropTypes.string,
    premium: PropTypes.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    averageRating: PropTypes.string.isRequired,
  }).isRequired,
};

const ProjectCard = ({
  project: {
    id,
    name,
    designerUser,
    categories,
    shortDescription,
    price,
    images,
    previousPrice,
    premium,
    averageRating,
  },
}) => {
  const history = useHistory();

  function onCardClick() {
    history.push(`/projects/${id}`);
  }

  return (
    <Container onClick={onCardClick} key={id}>
      <InnerContainer>
        <div>
          <Image src={images && images.length > 0 ? images[0].url : 'url da imagem padrao'} alt="Imagem do projeto" />
          <PremiumContainer>
            {premium && <PremiumStar color="#6342aa" title="Pro" />}
          </PremiumContainer>
          <InfoContainer>
            <NameContainer>
              <Headline>{name}</Headline>
              <RatingContainer>
                {parseInt(averageRating, 10) ? (
                  <>
                    <Rate>{averageRating}</Rate>
                    <FiStar size={16} color="#F39200" fill="#F39200" />
                  </>
                ) : (
                  <>
                    <Rate style={{ color: 'transparent' }}>.</Rate>
                    <FiStar size={20} color="transparent" />
                  </>
                )}
              </RatingContainer>
            </NameContainer>
            <Author>{designerUser.name}</Author>
            <Categories>
              {categories.map(c => (
                <Category key={c.id}>
                  <p>{c.name}</p>
                </Category>
              ))}
            </Categories>
            <Description>{shortDescription}</Description>
          </InfoContainer>
        </div>
        <PriceContainer>
          {previousPrice && <PreviousPrice>{previousPrice}</PreviousPrice>}
          <Price>{price}</Price>
        </PriceContainer>
      </InnerContainer>
    </Container>
  );
};

ProjectCard.propTypes = propTypes;

export default ProjectCard;
