import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10rem 0 5rem 0;
  flex-direction: row;

  button {
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    flex-direction: column;

    button {
      width: 80%;
    }
  }
`;

const InfoCard = styled.div`
  width: 25rem;
  margin-right: 3rem;

  @media (max-width: 720px) {
    width: 100%;
    margin-bottom: 5rem;
  }

  h1 {
    font-weight: 500;
    text-align: center;

    @media (min-width: 720px) {
      width: 25rem;
    }

    strong {
      font-weight: 800;
    }
  }

  > p {
    color: #9a9a9a;
    line-height: 2.4rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    text-align: center;
  }
`;

export { Container, InfoCard };
