import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';

const UserImage = styled.img`
  margin: auto;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  cursor: pointer;
  align-self: center;
  transition: opacity 1s;
  object-fit: cover;

  :hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }
`;

const UserIcon = styled(FaUserCircle)`
  width: 15rem;
  height: 15rem;
  cursor: pointer;
  align-self: center;
  transition: opacity 1s;

  :hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }
`;

export { UserIcon, UserImage };
