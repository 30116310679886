import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.div`
  background-color: rgba(154, 154, 154, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 15px;
  margin-bottom: 2.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin: 3rem auto 0 auto;
      width: 80%;
    }
  }
`;

const BalanceContainer = styled.div`
  small {
    font-weight: 500;
  }

  h1 {
    color: var(--primary-color);
    font-size: 4.8rem;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 4.2rem;
    }
  }
`;

const Headline = styled.h2`
  margin: 2rem 0;
`;

export { Container, Header, BalanceContainer, Headline };
