import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import DefaultLayout from '../pages/_layouts/default';
import LoggedLayout from '../pages/_layouts/logged';

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  privateRoute: PropTypes.string,
  loginRoute: PropTypes.bool,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

const defaultProps = {
  privateRoute: null,
  loginRoute: false,
  exact: false,
};

const RouteWrapper = ({
  component: Component,
  privateRoute,
  loginRoute,
  path,
  exact,
}) => {
  const { isLoggedIn, userType } = useAuth();

  if (loginRoute) {
    return <Route path={path} exact={exact} component={Component} />;
  }

  if (privateRoute) {
    if (!isLoggedIn) {
      return <Redirect to="/" />;
    }

    if (privateRoute === 'designer' && userType === 'cutter') {
      return <Redirect to="/" />;
    }

    return (
      <Route
        path={path}
        exact={exact}
        render={props => (
          <LoggedLayout type={privateRoute}>
            <Component {...props} />
          </LoggedLayout>
        )}
      />
    );
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={props => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};

RouteWrapper.propTypes = propTypes;
RouteWrapper.defaultProps = defaultProps;

export default RouteWrapper;
