import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(154, 154, 154, 0.1);
  padding: 1.5rem 2rem;
  border-radius: 15px;

  p {
    font-weight: 700;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Balance = styled.h1`
  color: #f39200;
`;

const Headline = styled.h2`
  margin: 2rem 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 5rem;

  button:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 720px) {
    margin-top: 2rem;
    margin-left: 0;
  }
`;

export { Header, Balance, Headline, ButtonsContainer };
