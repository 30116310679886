import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  min-width: ${props => `${props.minWidth || 0}px`};
  background-color: ${({ color, primary }) => {
    if (!primary) {
      return 'transparent';
    }

    if (color) {
      return color;
    }

    return '#F39200';
  }};
  border-radius: 6px;
  border: ${props => (props.primary ? 'none' : '2px #F39200 solid')};
  border: ${({ color, primary }) => {
    if (color) {
      return `2px ${color} solid`;
    }
    return primary ? 'none' : '2px #F39200 solid';
  }};
  transition: background-color 1s, opacity 1s;

  :hover {
    background-color: ${props => {
      if (!props.primary) {
        return '#ffeedb';
      }

      if (props.color) {
        return props.color;
      }

      return '#ffb84d';
    }};
    opacity: ${props => (props.color ? 0.6 : 1)};
    transition: background-color 0.3s, opacity 0.3s;
  }

  :disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`;

const ButtonText = styled.p`
  color: ${({ color, primary }) => {
    if (!primary) {
      return color || '#F39200';
    }

    return 'white';
  }};
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
  font-size: 1.5rem;
  width: 100%;
  text-align: ${props => (props.withIcon ? 'left' : 'center')};
`;

export { StyledButton, ButtonText };
