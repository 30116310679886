import styled from 'styled-components';

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.2rem;
  text-align: center;
`;

const Headline = styled.h2`
  font-size: 1.5rem;
  margin: 1rem 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > button:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const Content = styled.div`
  p {
    margin-bottom: 5px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-right: 5px;
  }

  label {
    font-size: 120%;
    margin-bottom: 2px;
    font-weight: medium;
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    maxHeight: '80vh',
  },
};

export {
  Title,
  Headline,
  ButtonsContainer,
  Content,
  CheckboxContainer,
  modalStyles,
};
