import PropTypes from 'prop-types';

import { Circle, Text, Container } from './styles';

const propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

const HowWorksItem = ({ text, Icon }) => (
  <Container>
    <Circle>
      <Icon color="white" size={40} />
    </Circle>
    <Text>{text}</Text>
  </Container>
);

HowWorksItem.propTypes = propTypes;

export default HowWorksItem;
