import styled from 'styled-components';

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 3.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: opacity 0.2s ease-in-out;
  right: 10px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 3.2rem;
    height: 0.45rem;
    background: var(--font-color);
    border-radius: 15px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const HiddenMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  min-height: 90vh;
  text-align: left;
  padding: 10rem 2rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 2;
  box-shadow: 0 10px 5px -5px gray;

  a,
  option {
    font-size: 2rem;
    padding: 3rem 0;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s linear;

    border-bottom: 1px solid #ccc;

    &:hover {
      color: #343078;
    }

    :last-child {
      margin-bottom: 3rem;
    }
  }

  button {
    margin-top: auto;
  }

  h1 {
    margin-top: 7rem;
    margin-bottom: 1.5rem;
  }
`;

export { StyledBurger, HiddenMenu };
