import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;

  form {
    max-width: 100%;

    @media (max-width: 1140px) {
      max-width: 70%;
    }

    @media (max-width: 800px) {
      max-width: 100%;
    }

    button:last-child {
      margin-top: 2rem;
      margin-left: auto;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-right: 2rem;
  }

  button {
    margin-top: 18px;
  }
`;

const AddFileInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

const AddImageButton = styled.button`
  border: 1px dashed #c0c0c0;
  height: 12rem;
  min-width: 12rem;
  max-width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;

  :hover {
    border: 1px dashed var(--primary-color);
  }
`;

const Image = styled.img`
  height: 12rem;
  width: 12rem;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 1rem;
  user-select: none;
`;

const ImageContainer = styled.div`
  svg.delete {
    z-index: 0;
    position: absolute;
    transform: translate(-25px, 3px);

    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  svg.drag {
    z-index: 0;
    position: absolute;
    transform: translate(-117px, 3px);

    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const ImagesContainer = styled(Scroll)`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;

  .featured-image {
    font-size: 1.2rem;
    text-align: center;
    transform: translateX(13.1rem);
  }
`;

const InputSmall = styled.small`
  margin-top: 0.2rem;
  color: #9a9a9a;
`;

export {
  Container,
  InputContainer,
  AddFileInput,
  AddImageButton,
  Image,
  ImageContainer,
  ImagesContainer,
  InputSmall,
};
