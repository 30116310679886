import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 2rem;
  margin: 0 0.5rem;
  width: 27rem;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserImage = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  margin-right: 1rem;
`;

const UserName = styled.p`
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;

const ProjectName = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Review = styled.p`
  color: #9a9a9a;
  margin-top: 1rem;
  white-space: normal;
`;

const RateContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;

  svg:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export {
  Container,
  UserInfo,
  UserImage,
  UserName,
  ProjectName,
  Review,
  RateContainer,
};
