import styled from 'styled-components';

const Container = styled.section`
  margin-top: 10rem;
`;

const ItemsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Line = styled.div`
  height: 1px;
  border-top: 1px solid #a4a4a4;
  margin: 0px 10rem 0px 10rem;
  transform: translateY(-7rem);
  z-index: -1;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Title = styled.p`
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 4rem;

  strong {
    font-weight: 900;
  }
`;

export { ItemsContainer, Line, Title, Container };
