import styled from 'styled-components';

const Container = styled.div`
  max-width: 700px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;

    button {
      margin: 2rem auto;
      padding: 0 10rem;
    }
  }
`;

const Title = styled.h1`
  color: var(--primary-color);
  margin-bottom: 2.5rem;
  font-size: 4rem;
`;

const Text = styled.p`
  text-align: center;
  max-width: 700px;
  color: #707070;
  margin: 0 2rem 2.5rem 2rem;
`;

export { Container, Title, Text };
