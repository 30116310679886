import { useState, useEffect } from 'react';

import api from '../../services/api';
import FormatCurrency from '../../utils/FormatCurrency';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/Button';
import DesignerPaymentItem from '../../components/DesignerPaymentItem';
import Notification from '../../components/Notification';
import ModalWithInput from '../../components/ModalWithInput';

import { Container, Header, BalanceContainer, Headline } from './styles';

const DesignerWallet = () => {
  const { loading: authLoading } = useAuth();
  const [wallet, setWallet] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isWithdrawMoneyOpen, setWithdrawMoneyOpen] = useState(false);

  async function fetchWallet() {
    try {
      setLoading(true);
      const { data } = await api.get('/designer_balance');
      setWallet({
        balance: FormatCurrency(data.balance),
        payments: data.payments.map(t => ({
          ...t,
          amount: FormatCurrency(t.amount),
        })),
        blockedAmount: data.blockedAmount,
      });
    } catch (err) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Erro ao buscar dados da carteira',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!authLoading) {
      fetchWallet();
    }
  }, [authLoading]);

  function handleWithdrawMoneyButtonClick() {
    setWithdrawMoneyOpen(true);
  }

  function handleWithdrawMoneyModalClose() {
    setWithdrawMoneyOpen(false);
  }

  async function handleWithdrawMoneyModalConfirm(amount) {
    setWithdrawMoneyOpen(false);
    try {
      setLoading(true);
      await api.post('/withdrawal_money', { amount });
      fetchWallet();
      Notification({
        message: 'Sucesso ao realizar pedido de retirada',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      const message = err.response.data
        ? err.response.data.error
        : 'Erro ao fazer pedido de retirada';

      Notification({
        message,
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  return isLoading ? (
    <div className="loader" />
  ) : (
    <>
      <ModalWithInput
        isOpen={isWithdrawMoneyOpen}
        onClose={handleWithdrawMoneyModalClose}
        onConfirm={handleWithdrawMoneyModalConfirm}
        text="Digite o valor que você desejar sacar de sua conta"
        title="Pedido de saque"
        inputType="number"
      />
      <Container>
        <Header>
          <BalanceContainer>
            <small>Você tem</small>
            <h1>{wallet.balance}</h1>
            <small>*Você possui R${wallet.blockedAmount} bloqueados</small>
          </BalanceContainer>

          <Button
            text="Sacar dinheiro"
            primary
            onClick={handleWithdrawMoneyButtonClick}
          />
        </Header>
        {wallet.payments && wallet.payments.length > 0 && (
          <>
            <Headline>Vendas</Headline>
            <div>
              {wallet.payments.map(t => (
                <DesignerPaymentItem key={t.id} transaction={t} />
              ))}
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default DesignerWallet;
