import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 1rem 3rem 3rem 3rem;
  margin-top: 4rem;
  min-height: 300px;

  h2 {
    margin-top: 2rem;
  }

  img {
    margin-top: 1rem;
    cursor: pointer;
  }

  svg {
    margin-right: 1.5rem;
  }

  a,
  p {
    color: #9a9a9a;
  }
`;

const MaterialItem = styled.div`
  display: flex;
  align-items: center;
  margin: 1.3rem 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  transition: color 0.2s;

  :hover {
    transition: color 0.5s;
    p {
      color: ${({ clickable }) => (clickable ? '#251af0' : '#9a9a9a')};
    }
  }
`;

const TutorialButton = styled.button`
  background-color: transparent;
  border: none;

  :hover {
    opacity: 0.7;
  }
`;

export { Container, TutorialButton, MaterialItem };
