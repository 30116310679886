import { useState, useEffect } from 'react';

import api from '../../services/api';
import FormatCurrency from '../../utils/FormatCurrency';
import { useAuth } from '../../hooks/auth';
import TransactionsList from '../../components/TransactionsList';
import NoSalesFound from '../../components/NoSalesFound';
import Notification from '../../components/Notification';

import Headline from './styles';

const DesignerSales = () => {
  const { loading: authLoading } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get('/designer_sales');
          const formattedProjects = data.map(p => ({
            ...p,
            amount: FormatCurrency(p.amount),
          }));
          setTransactions(formattedProjects);
        } catch (err) {
          Notification({
            type: Notification.types.ERROR,
            message: 'Erro ao buscar vendas',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authLoading]);

  function renderTransactionsList() {
    if (transactions.length === 0) {
      return <NoSalesFound text="Nenhuma venda realizada" />;
    }

    return <TransactionsList transactions={transactions} />;
  }
  return (
    <>
      <Headline>Minhas vendas</Headline>
      {isLoading ? <div className="loader" /> : renderTransactionsList()}
    </>
  );
};

export default DesignerSales;
