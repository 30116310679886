import styled from 'styled-components';

const Video = styled.iframe`
  width: ${({ customWidth }) => `${customWidth * 0.9}px`};
  height: ${({ customHeight }) => `${customHeight}px`};

  @media (max-width: 1080px) {
    height: ${({ customHeight }) => `${customHeight}px`};
    width: ${({ customWidth }) => `${customWidth * 1.15}px`};
  }

  @media (max-width: 720px) {
    height: ${({ customHeight }) => `${customHeight * 0.7}px`};
    width: ${({ customWidth }) => `${customWidth * 1.5}px`};
  }

  @media (max-width: 600px) {
    width: ${({ customWidth }) => `${customWidth * 1.5}px`};
    height: ${({ customHeight }) => `${customHeight * 0.6}px`};
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

export { Video, modalStyles };
