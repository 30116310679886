import styled from 'styled-components';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 200px;

  > h1 {
    font-size: 2.4rem;
  }
`;

const Menu = styled.ul`
  margin-bottom: 1.5rem;
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin: 2.5rem 0;
  transition: opacity 0.5s;

  :hover {
    opacity: 0.6;
    transition: opacity 0.2s;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    margin-left: 2rem;
  }
`;

export { Container, MenuItem, Menu };
