import styled from 'styled-components';

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;

  > button:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const Input = styled.input`
  border: 1px solid #9a9a9a;
  border-radius: 8px;
  width: 100%;
  margin-top: 1.6rem;
`;

const Error = styled.p`
  color: #ff0000;
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export { Headline, Input, ButtonsContainer, Error, modalStyles };
