import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    max-width: 30rem;
    margin-top: 2rem;
    text-align: center;
  }

  > p {
    margin: 2.5rem 0;
    max-width: 50rem;
    text-align: center;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { Container };
