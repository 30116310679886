import styled from 'styled-components';

const Container = styled.section`
  display: grid;
  grid-template-columns: 40% 22% 40%;
  justify-content: space-between;
  margin-top: 10rem;
  overflow: hidden;

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

const Headline = styled.p`
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 2rem;

  strong {
    font-weight: 900;
  }
`;

const LeftContainer = styled.div`
  background-image: url(${props => props.backgroundUrl || ''});
  background-repeat: no-repeat;
  height: 61rem;

  display: grid;
  grid-template-columns: 30% 30% 20%;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 720px) {
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
`;

const RightContainer = styled.div`
  background-image: url(${props => props.backgroundUrl || ''});
  background-position: right;
  background-repeat: no-repeat;
  height: 61rem;
  display: flex;
  justify-content: flex-end;

  div {
    display: grid;
    grid-template-columns: 20% 45% 15%;
    height: 90%;
    width: 90%;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const PersonImage = styled.img`
  object-fit: cover;
  width: ${props => `${props.width}px`};
  border-radius: 50%;
`;

export {
  Container,
  Headline,
  LeftContainer,
  CenterContainer,
  RightContainer,
  PersonImage,
};
