import styled from 'styled-components';

import img from '../../assets/image/login-background.png';

const Container = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const LeftHalfContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 720px) {
    width: 100%;
  }

  button {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
`;

const LoginImage = styled.img`
  width: 100%;
`;

const Logo = styled.img`
  width: 21rem;
  margin-top: 10rem;
`;

const Headline = styled.p`
  font-size: 2.8rem;
  font-weight: 500;
  color: #9a9a9a;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const ForgotPassword = styled.p`
  color: #9a9a9a;
  cursor: pointer;
  margin-bottom: 5rem;

  :hover {
    text-decoration: underline;
  }
`;

const LoginInput = styled.input`
  border: 1px #9a9a9a solid;
  border-radius: 12px;
  width: 70%;
  padding-left: 2rem;
  margin-top: 1rem;
`;

const RightHalfContainer = styled.div`
  width: 50%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div {
    position: absolute;
    bottom: 4rem;
    right: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > p {
      color: white;
      font-size: 2.6rem;
      font-weight: 700;
      max-width: 300px;
      text-align: right;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 720px) {
    width: 100%;
    height: 300px;
    justify-content: center;
    background: ${() => `lightblue url(${img}) no-repeat center`};
    background-size: 100% 300px;

    div {
      position: unset;
      align-items: center;
      justify-content: center;
      height: 150%;

      p {
        text-align: center;
      }
    }

    img {
      position: absolute;
      display: none;
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :focus-within {
    svg {
      visibility: visible;
    }
  }

  input {
    width: 70%;
    margin-left: 24px;
  }

  svg {
    margin-top: 1rem; // need to be equal to LoginInput margin-top
    transform: translateX(-40px);
    visibility: ${({ showPassword }) => (showPassword ? 'visible' : 'hidden')};
    cursor: pointer;
    user-select: none;
    transition: opacity 0.2s;

    :hover {
      opacity: 0.8;
      transition: opacity 0.2s;
    }
  }
`;

export {
  Container,
  Logo,
  Headline,
  ForgotPassword,
  LoginInput,
  LeftHalfContainer,
  RightHalfContainer,
  LoginImage,
  InputContainer,
};
