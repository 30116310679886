import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import PropTypes from 'prop-types';

import VideoModal from '../VideoModal';
import PdfSvg from '../../assets/svg/pdf.svg';

import { Container, TutorialButton, MaterialItem } from './styles';

const propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      storeLink: PropTypes.string,
    })
  ),
  videoUrl: PropTypes.string,
  projectPdf: PropTypes.string,
};

const defaultProps = {
  materials: [],
  videoUrl: '',
  projectPdf: '',
};

const ProjectInfoDetails = ({ materials, videoUrl, projectPdf }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  function handleTutorialButtonClick() {
    setVideoModalOpen(true);
  }

  function handleVideoModalClose() {
    setVideoModalOpen(false);
  }

  function handlePdfClick() {
    window.open(projectPdf.url);
  }

  function handleMaterialClick(material) {
    if (material.storeLink) {
      window.open(material.storeLink);
    }
  }

  return (
    <>
      <VideoModal
        videoUrl={videoUrl}
        isOpen={videoModalOpen}
        onClose={handleVideoModalClose}
      />
      <Container>
        <div>
          <h2>Detalhes do projeto</h2>
          {materials.map(m => (
            <MaterialItem
              key={m.id}
              onClick={() => handleMaterialClick(m)}
              clickable={!!m.storeLink}
              title="Ver material na loja"
            >
              <FaCheck color="#F39200" />
              <p>{`Recomendado para ${m.name}`} </p>
            </MaterialItem>
          ))}
        </div>
        {videoUrl && (
          <div>
            <h2>Tutorial</h2>
            <TutorialButton
              type="button"
              onClick={handleTutorialButtonClick}
              title="Abrir player de vídeo"
            >
              <p>Assistir tutorial</p>
            </TutorialButton>
          </div>
        )}
        {projectPdf && (
          <div>
            <h2>Arquivos</h2>
            <div onClick={handlePdfClick} role="button" tabIndex={0}>
              <img src={PdfSvg} alt="" />
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

ProjectInfoDetails.propTypes = propTypes;
ProjectInfoDetails.defaultProps = defaultProps;

export default ProjectInfoDetails;
