import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import Button from '../Button';
import ProModal from '../ProModal';

import { StyledBurger, HiddenMenu } from './styles';

const BurguerMenu = () => {
  const history = useHistory();
  const { isLoggedIn, userType, signOut } = useAuth();

  const [isOpen, setOpen] = useState(false);
  const [proModalOpen, setProModal] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  function handleLinkClick() {
    setOpen(false);
  }

  function handleLoginButtonClick() {
    history.push('/login');
  }

  function handleProButtonClick() {
    setOpen(false);
    setProModal(true);
  }

  function handleProModalClose() {
    setProModal(false);
  }

  function handleLogoutButtonClick() {
    setOpen(false);
    signOut();
  }

  function closeMenu() {
    setOpen(false);
  }

  function renderMyAccountMenu() {
    return userType === 'designer' ? (
      <>
        <Link to="/designer/projects" onClick={closeMenu}>
          Meus projetos
        </Link>
        <Link to="/designer/remuneration" onClick={closeMenu}>
          Remuneração
        </Link>
        <Link to="/designer/wallet" onClick={closeMenu}>
          Minha carteira
        </Link>
        {/* <Link to="/designer/sales" onClick={closeMenu}>
          Minhas vendas
        </Link> */}
        <Link to="/designer/profile" onClick={closeMenu}>
          Meus dados
        </Link>
        <Link to="/designer/buys" onClick={closeMenu}>
          Minhas compras
        </Link>
        <Link to="/" onClick={handleLogoutButtonClick}>
          Sair do sistema
        </Link>
      </>
    ) : (
      <>
        <Link to="/cutter/profile" onClick={closeMenu}>
          Meus dados
        </Link>
        <Link to="/cutter/projects" onClick={closeMenu}>
          Meus projetos
        </Link>
        <Link to="/cutter/courses" onClick={closeMenu}>
          Meus cursos
        </Link>
        <Link to="/cutter/wallet" onClick={closeMenu}>
          Minha carteira
        </Link>
        <option onClick={handleProButtonClick}>Me tornar PRO</option>
        <Link to="/" onClick={handleLogoutButtonClick}>
          Sair do sistema
        </Link>
      </>
    );
  }

  return (
    <>
      <ProModal isOpen={proModalOpen} onClose={handleProModalClose} />
      <StyledBurger open={isOpen} onClick={() => setOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <HiddenMenu open={isOpen}>
        <Link to="/projects" onClick={handleLinkClick}>
          Explorar
        </Link>
        <Link to="/free-projects" onClick={handleLinkClick}>
          Gratuitos
        </Link>
        {isLoggedIn ? (
          <>
            <h1>Minha Conta</h1>
            {renderMyAccountMenu()}
          </>
        ) : (
          <>
            <Link to="/register" onClick={handleLinkClick}>
              Cadastre-se
            </Link>
            <Button primary text="Login" onClick={handleLoginButtonClick} />
          </>
        )}
      </HiddenMenu>
    </>
  );
};

export default BurguerMenu;
