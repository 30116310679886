import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ededed;
`;

const Description = styled.p`
  font-weight: 700;
  margin-right: 15px;
`;

const Amount = styled.p`
  margin-left: auto;
  font-weight: 700;
  align-self: flex-start;
  white-space: nowrap;
`;

const DateText = styled.p`
  font-weight: 300;
  font-size: 1.3rem;
  margin-top: 0.4rem;
`;

const IconContainer = styled.div`
  background-color: #f0f0f0;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 100%;
  margin-right: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, Description, Amount, DateText, IconContainer };
