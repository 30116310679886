import styled from 'styled-components';

const FiltersContainer = styled.div`
  width: 100%;
  border: 1px solid #a3a3a3;
  border-radius: 15px;
  padding: 2.5rem 5.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  input {
    margin-right: 1rem;
  }

  label {
    font-weight: 500;
  }
`;

const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Headline = styled.p`
  font-size: 2.6rem;
  font-weight: 700;
`;

const Line = styled.div`
  height: 2px;
  width: 100%;
  border-top: 2px solid #a3a3a3;
  margin: 0 10px;
`;

const Divider = styled(Line)`
  margin: 2rem 0px 1rem 0px;
`;

const Text = styled.p`
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 2rem 0;

  input {
    transform: scale(1.2);
    box-shadow: none;
  }
`;

export {
  FiltersContainer,
  Headline,
  Line,
  HeadlineContainer,
  Text,
  CheckboxContainer,
  Divider,
};
