import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';
import api from '../../services/api';

import {
  Headline,
  Input,
  ButtonsContainer,
  Error,
  modalStyles,
} from './styles';

const modalCloseTimeout = 500;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CheckPasswordModal = ({ isOpen, onSuccess, onClose }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  async function handleConfirmButtonClick() {
    if (!value) return;

    try {
      await api.post('/check_password', { password: value });
      onSuccess();
      setTimeout(() => {
        setValue('');
      }, modalCloseTimeout);
    } catch (err) {
      setError(err.response.data ? err.response.data.error : 'Ocorreu um erro');
    }
  }

  function handleInputChange(e) {
    setValue(e.target.value);
  }

  function handleCloseButtonClick() {
    onClose();

    setTimeout(() => {
      setValue('');
    }, modalCloseTimeout);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseButtonClick}
      contentLabel="Validar senha"
      style={modalStyles}
      closeTimeoutMS={modalCloseTimeout}
    >
      <Headline>Digite sua senha</Headline>
      <p>Informe qual é a senha da sua conta para finalizar a compra</p>
      <Input type="password" onChange={handleInputChange} value={value} />
      {error && <Error>{error}</Error>}
      <ButtonsContainer>
        <Button text="Cancelar" onClick={handleCloseButtonClick} />
        <Button text="Confirmar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

CheckPasswordModal.propTypes = propTypes;

export default CheckPasswordModal;
