import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid var(--primary-color);
  border-radius: 17px;
  cursor: pointer;
  transition: opacity 0.5s;
  min-height: 47rem;

  :hover {
    opacity: 0.95;
    background-color: #fafafa;
    transition: opacity 0.2s;
  }
`;

const Image = styled.img`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  object-fit: cover;
  height: 25rem;
`;

const Author = styled.p`
  font-weight: 500;
  color: #9f9f9f;
  font-size: 1.3rem;
  margin-top: 0.5rem;
`;

const Headline = styled.p`
  font-weight: 700;
  font-size: 1.7rem;
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  white-space: nowrap;
`;

const Category = styled.div`
  background: rgba(247, 182, 0, 0.12);
  border-radius: 6px;
  padding: 0.4rem 1.2rem;
  margin: 0.2rem 0;

  :not(:last-child) {
    margin-right: 1rem;
  }

  > p {
    color: var(--accent-color);
  }
`;

const Description = styled.p`
  color: #9a9a9a;
`;

const PriceContainer = styled.div`
  margin-left: 2rem;
  margin-bottom: 2rem;
`;

const Price = styled.p`
  color: var(--accent-color);
  font-size: 2.2rem;
  font-weight: 700;
`;

const PreviousPrice = styled.p`
  color: #a9a9a9;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: line-through;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2rem 2rem 2rem;
`;

const PremiumStar = styled(FaStar)`
  cursor: default;
  transform: translateY(-20.7rem);
  width: 2.5rem;
  height: 2.5rem;

  @media (max-width: 1080px) {
    transform: translateY(-19.7rem);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Rate = styled.p`
  font-weight: 500;
  font-size: 1.7rem;
  margin-right: 5px;
`;

const PremiumContainer = styled.div`
  margin-top: -38px;
  height: 4rem;
  padding: 0 1rem;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Container,
  InnerContainer,
  Image,
  Headline,
  Description,
  Price,
  Categories,
  Category,
  Author,
  InfoContainer,
  PriceContainer,
  PreviousPrice,
  PremiumStar,
  RatingContainer,
  Rate,
  PremiumContainer,
  NameContainer,
};
