import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Button from '../Button';
import Notification from '../Notification';
import ReviewModal from '../ReviewModal';
import ReviewsList from '../ReviewsList';

import { InfoCard, Container } from './styles';

const propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
};

const ProjectReviews = ({ reviews, projectId, projectName }) => {
  const { isLoggedIn } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [mReviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(reviews);
  }, []);

  function handleReviewButtonClick() {
    if (!isLoggedIn) {
      Notification({
        message: 'Faça o login para deixar uma opinião',
        type: Notification.types.ERROR,
      });
      return;
    }
    setModalOpen(true);
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  async function fetchReviews() {
    try {
      const { data } = await api.get(`/project/${projectId}/reviews`);
      setReviews(data);
    } catch (err) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Ocorreu um erro ao buscar avaliações',
      });
    }
  }

  async function handleConfirmModal(review) {
    setModalOpen(false);
    try {
      await api.post(`/project/${projectId}/reviews`, review);
      Notification({
        type: Notification.types.SUCCESS,
        message: 'Avaliação enviada com sucesso',
      });
      fetchReviews();
    } catch (err) {
      if (err.response) {
        Notification({
          type: Notification.types.ERROR,
          message: err.response.data.error,
        });
      } else {
        Notification({
          type: Notification.types.ERROR,
          message: 'Ocorreu um erro ao enviar sua avaliação',
        });
      }
    }
  }

  return (
    <>
      <ReviewModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
      />
      <Container>
        <InfoCard>
          <h1>
            Conheça a opinião de nossos <strong>clientes</strong>
          </h1>
          <p>
            reprehenderit anim laboris ut cupidatat elit proident est aliqua
            sunt et nostrud minim est ullamcoreprehenderit
          </p>
          <Button text="Deixar opinião" onClick={handleReviewButtonClick} />
        </InfoCard>
        <ReviewsList reviews={mReviews} projectName={projectName} />
      </Container>
    </>
  );
};

ProjectReviews.propTypes = propTypes;

export default ProjectReviews;
