import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import { Headline, Input, ButtonsContainer, modalStyles } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  inputType: PropTypes.string,
};

const defaultProps = {
  inputType: 'text',
};

const modalCloseTimeout = 500;

const ModalWithInput = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  text,
  inputType,
}) => {
  const [value, setValue] = useState('');

  function handleConfirmButtonClick() {
    if (!value) return;

    onConfirm(value);

    setTimeout(() => {
      setValue('');
    }, modalCloseTimeout);
  }

  function handleInputChange(e) {
    setValue(e.target.value);
  }

  function handleCloseButtonClick() {
    onClose();

    setTimeout(() => {
      setValue('');
    }, modalCloseTimeout);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseButtonClick}
      contentLabel={title}
      style={modalStyles}
      closeTimeoutMS={modalCloseTimeout}
    >
      <Headline>{title}</Headline>
      <p>{text}</p>
      <Input type={inputType} onChange={handleInputChange} value={value} />
      <ButtonsContainer>
        <Button text="Cancelar" onClick={handleCloseButtonClick} />
        <Button text="Confirmar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

ModalWithInput.propTypes = propTypes;
ModalWithInput.defaultProps = defaultProps;

export default ModalWithInput;
