import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../Button';

import {
  Title,
  Headline,
  ButtonsContainer,
  Content,
  modalStyles,
  CheckboxContainer,
} from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const PolicyModal = ({ isOpen, onClose, onConfirm }) => {
  const [accepted, setAccepted] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Realizar depósito"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Content>
        <Title>Política de Licenciamento de Arquivos para Portal Dueit</Title>

        <Headline>1. Introdução</Headline>
        <p>
          A política de licenciamento de arquivos para o portal de vendas tem
          como objetivo estabelecer as diretrizes e restrições relacionadas ao
          uso, distribuição e proteção dos arquivos disponibilizados aos
          clientes através do portal. Essa política se aplica a todos os tipos
          de arquivos disponíveis para venda, como documentos, imagens, vídeos,
          software ou qualquer outra forma de conteúdo digital sujeito a
          licenciamento.
        </p>

        <Headline>2. Propriedade dos Arquivos</Headline>
        <p>
          Todos os arquivos disponibilizados no portal são considerados
          propriedade da organização responsável pelo portal. Essa propriedade
          inclui o conteúdo dos arquivos, bem como os direitos autorais e outros
          direitos de propriedade intelectual associados.
        </p>

        <Headline>3. Licenciamento de Arquivos</Headline>
        <p>
          3.1. Compra de Licença: Ao adquirir um arquivo através do portal, o
          cliente recebe uma licença de uso para esse arquivo específico. Essa
          licença permite ao cliente utilizar o arquivo para produzir objetos,
          produtos ou realizar qualquer atividade permitida, desde que esteja de
          acordo com os termos estabelecidos nesta política.
        </p>

        <p>
          3.2. Restrições de Distribuição: A licença adquirida pelo cliente não
          permite a distribuição, venda ou revenda do arquivo em si. O cliente
          tem permissão para usar o arquivo para a produção de objetos e vender
          esses objetos como produtos finais, mas não pode disponibilizar ou
          comercializar o arquivo original para outros.
        </p>

        <Headline>4. Uso Consciente e Responsável</Headline>
        <p>
          4.1. Conformidade com as Licenças: O cliente é responsável por cumprir
          os termos da licença adquirida. Isso inclui respeitar as restrições de
          distribuição do arquivo e utilizá-lo apenas para os fins permitidos,
          conforme estabelecido na política.
        </p>

        <p>
          4.2. Proteção dos Arquivos: É responsabilidade do cliente adotar
          medidas adequadas para proteger os arquivos adquiridos contra acesso
          não autorizado, cópia ou distribuição indevida. Isso pode incluir a
          implementação de medidas de segurança, como a restrição de acesso aos
          arquivos e a utilização de tecnologias de proteção de direitos
          autorais, quando aplicável.
        </p>

        <Headline>5. Responsabilidades da Organização</Headline>
        <p>
          5.1. Licenciamento Adequado: A organização responsável pelo portal
          deve garantir que todos os arquivos disponibilizados para venda
          possuam licenças adequadas e que estejam em conformidade com as leis
          de propriedade intelectual aplicáveis.
        </p>

        <p>
          5.2. Informações Claras sobre as Licenças: A organização deve fornecer
          informações claras e precisas sobre as restrições e permissões
          associadas a cada arquivo disponível para venda. Essas informações
          devem estar disponíveis de forma acessível aos clientes antes da
          compra.
        </p>

        <Headline>6. Sanções por Violação</Headline>

        <p>
          6.1. Violação dos Termos da Licença: No caso de violação dos termos da
          licença adquirida, a organização reserva-se o direito de tomar medidas
          apropriadas, que podem incluir a suspensão ou revogação da licença, a
          restrição do acesso ao portal ou outras ações legais cabíveis.
        </p>

        <p>
          6.2. Denúncias e Reclamações: Os clientes e terceiros são encorajados
          a relatar quaisquer violações ou suspeitas de violações desta política
          por meio dos canais estabelecidos pela organização, a fim de garantir
          o cumprimento adequado das licenças e proteger os direitos de
          propriedade intelectual.
        </p>

        <CheckboxContainer>
          <input
            id="accept-terms"
            type="checkbox"
            checked={accepted}
            onChange={e => setAccepted(e.target.checked)}
          />
          <label htmlFor="accept-terms">
            Aceitar termos de política de Licenciamento de Arquivos
          </label>
        </CheckboxContainer>
      </Content>
      <ButtonsContainer>
        <Button text="Cancelar" onClick={onClose} title="Cancelar compra" />

        <Button
          text="Confirmar"
          primary
          onClick={onConfirm}
          isDisabled={!accepted}
          title={
            accepted
              ? 'Confirmar compra'
              : 'Aceite os termos para confirmar a compra'
          }
        />
      </ButtonsContainer>
    </Modal>
  );
};

PolicyModal.propTypes = propTypes;

export default PolicyModal;
