import WelcomePng from '../../assets/image/welcome-card-image.png';

import { Container, WelcomeImage, Headline, Text } from './styles';

const WelcomeSection = () => (
  <Container>
    <WelcomeImage src={WelcomePng} alt="Mulher lendo um projeto" />
    <div>
      <Headline>
        <strong>Os melhores projetos</strong> para Corte a Laser estão aqui
      </Headline>
      <Text>
        No Portal Due Laser suas inspirações e ideias viram projetos, que viram
        produtos. Aqui você divide seu talento para criações e ganha por isso.
      </Text>
      <br />
      <Text>
        Você desenvolve o design de peças exclusivas e inspiradoras e
        comercializa seus projetos de maneira rápida e prática. Faça parte do
        time que inspira outros artesãos a entrarem para o mundo dos
        personalizados com corte a laser.
      </Text>
      <br />
      <Text>Por isso que nós sempre dizemos: “você cria e a Due corta!”</Text>
    </div>
  </Container>
);

export default WelcomeSection;
