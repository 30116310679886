import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import ModalWithInput from '../../components/ModalWithInput';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import LogoSvg from '../../assets/svg/logo-blue.svg';
import LoginImg from '../../assets/image/login-background.png';

import {
  Container,
  Logo,
  Headline,
  ForgotPassword,
  LoginInput,
  LeftHalfContainer,
  RightHalfContainer,
  LoginImage,
  InputContainer,
} from './styles';
import TermsModal from '../../components/TermsModal';

const Login = () => {
  const history = useHistory();
  const { signIn } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showPassowrd, setShowPassword] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [sessionData, setSessionData] = useState({});

  async function persistLogin(data, persistTermAccept = false) {
    signIn({
      newToken: data.token,
      newUserImage: data.userImage || '',
      newUserType: data.userType,
      newUserName: data.userName,
    });

    if (persistTermAccept) {
      await api.post('/accept_terms');
    }

    history.push(`/projects`);
  }

  async function handleLogin() {
    if (email === '' || password === '') {
      Notification({
        message: 'Informe email e senha',
        type: Notification.types.ERROR,
      });
      return;
    }

    try {
      setLoading(false);
      const { data } = await api.post('/auth', { email, password });

      if (data.mustAcceptTerms) {
        setSessionData(data);
        setTermsModalOpen(true);
        return;
      }

      persistLogin(data);
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
      } else {
        Notification({
          message: 'Erro ao realizar login',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleForgotPasswordClick() {
    setPasswordModalOpen(true);
  }

  async function handleClosePasswordModal() {
    setPasswordModalOpen(false);
  }

  async function handleResetPassword(resetEmail) {
    try {
      setLoading(true);
      setPasswordModalOpen(false);

      await api.post('/reset_password', { email: resetEmail });

      Notification({
        message: 'Verifique sua caixa de entrada do email',
        type: Notification.types.SUCCESS,
      });
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
      } else {
        Notification({
          message: 'Ocorreu um erro ao resetar senha',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function handleEyeIconClick() {
    setShowPassword(!showPassowrd);
  }

  function handleTermsModalClose() {
    setSessionData({});
    setTermsModalOpen(false);
  }

  function handleTermsModalAccept() {
    setTermsModalOpen(false);
    persistLogin(sessionData, true);
  }

  return (
    <>
      {isLoading && <div className="loader loader-accent" />}
      <TermsModal
        title="Termos de uso do portal"
        text={sessionData.terms}
        isOpen={termsModalOpen}
        onAccept={handleTermsModalAccept}
        onClose={handleTermsModalClose}
      />
      <ModalWithInput
        title="Recuperar senha"
        text="Digite o email que está vinculado a sua conta"
        isOpen={passwordModalOpen}
        onConfirm={handleResetPassword}
        onClose={handleClosePasswordModal}
      />
      <Container>
        <LeftHalfContainer>
          <Logo src={LogoSvg} alt="Logo" />
          <Headline>Acesse sua conta</Headline>
          <LoginInput
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <InputContainer showPassword={showPassowrd}>
            <LoginInput
              type={showPassowrd ? 'text' : 'password'}
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {showPassowrd ? (
              <IoMdEye color="#a9a9a9" size={24} onClick={handleEyeIconClick} />
            ) : (
              <IoMdEyeOff
                color="#a9a9a9"
                size={24}
                onClick={handleEyeIconClick}
              />
            )}
          </InputContainer>
          <Button text="Acessar sua conta" primary onClick={handleLogin} />
          <ForgotPassword onClick={handleForgotPasswordClick}>
            Esqueci minha senha
          </ForgotPassword>
        </LeftHalfContainer>
        <RightHalfContainer>
          <LoginImage src={LoginImg} />
          <div>
            <p>Deseja acessar sua conta como designer?</p>
            <Button text="Sou designer" primary onClick={handleLogin} />
          </div>
        </RightHalfContainer>
      </Container>
    </>
  );
};

export default Login;
