import PropTypes from 'prop-types';
import { FiDollarSign } from 'react-icons/fi';

import {
  Container,
  Description,
  Amount,
  DateText,
  IconContainer,
} from './styles';

const propTypes = {
  transaction: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    paidAt: PropTypes.string.isRequired,
  }).isRequired,
};

const TransactionItem = ({
  transaction: { amount, paymentDate, description, paidAt },
}) => {
  function formatDate(d) {
    const date = new Date(d);
    date.setHours(date.getHours() + 4);
    return date.toLocaleString('pt-BR').split(' ')[0];
  }

  return (
    <Container>
      <IconContainer>
        <FiDollarSign color="#000" size={22} />
      </IconContainer>
      <div>
        <Description>{description}</Description>
        {paidAt === null ? (
          <DateText>{`Previsão de pagamento para ${formatDate(
            paymentDate
          )}`}</DateText>
        ) : (
          <DateText>{`Pago em ${formatDate(paidAt)}`}</DateText>
        )}
      </div>
      <Amount>{amount}</Amount>
    </Container>
  );
};

TransactionItem.propTypes = propTypes;

export default TransactionItem;
