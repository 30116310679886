import { useEffect, useState } from 'react';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import DesignerProjectCard from '../../components/DesignerProjectCard';
import Notification from '../../components/Notification';
import NoProjectsFound from '../../components/NoProjectsFound';

import { Container, Header, ProjectsList, SelectLabel, Select } from './styles';

const DesignerProjects = () => {
  const { loading: authLoading } = useAuth();
  const [projects, setProjects] = useState([]);
  const [hasProjects, setHasProjects] = useState([]);
  const [filter, setFilter] = useState('Todos');
  const [isLoading, setLoading] = useState(true);

  function setProjectsList(data) {
    const projectsList = data.map(p => ({
      ...p,
      createdAt: new Date(p.createdAt).toLocaleDateString({
        locale: 'pt-br',
      }),
    }));
    setProjects(projectsList);
  }

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get('/designer_projects');
          setHasProjects(data.length > 0);
          setProjects(
            data.map(p => ({
              ...p,
              createdAt: new Date(p.createdAt).toLocaleDateString('pt-BR'),
            }))
          );
        } catch (err) {
          Notification({
            type: Notification.types.ERROR,
            message: 'Erro ao buscar projetos',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authLoading]);

  async function handleFilterChange(e) {
    setFilter(e.target.value);
    try {
      setLoading(true);
      const { data } = await api.get(
        `/designer_projects?situation=${e.target.value}`
      );
      setProjectsList(data);
    } catch (err) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Erro ao buscar projetos',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderProjectsList() {
    if (!hasProjects) {
      return (
        <>
          <Header>
            <h1>Meus projetos</h1>
          </Header>

          <NoProjectsFound text="Você ainda não publicou nenhum projeto" />
        </>
      );
    }

    return (
      <>
        <Header>
          <h1>Meus projetos</h1>
          <div>
            <SelectLabel>Filtrar por</SelectLabel>
            <Select value={filter} onChange={handleFilterChange}>
              <option value="Aprovado">Aprovados</option>
              <option value="Verificar">Verificar</option>
              <option value="Em%20análise">Em análise</option>
              <option value="Todos">Todos</option>
            </Select>
          </div>
        </Header>
        {projects.length === 0 ? (
          <NoProjectsFound text="Nenhum projeto encontrado" />
        ) : (
          <ProjectsList>
            {projects.map(p => (
              <DesignerProjectCard key={p.id} project={p} />
            ))}
          </ProjectsList>
        )}
      </>
    );
  }

  return isLoading ? (
    <div className="loader" />
  ) : (
    <Container>{renderProjectsList()}</Container>
  );
};

export default DesignerProjects;
