import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 1rem auto;
  flex-direction: column;
  align-items: center;
  max-width: 700px;

  button {
    margin-top: 1rem;
  }

  form {
    width: 100%;

    button {
      margin: 2rem auto;
      padding: 0 10rem;
    }
  }
`;

const Text = styled.h3`
  margin-bottom: 1rem;
`;

export { Container, Text };
