import { Container, Draggable } from 'react-smooth-dnd';
import PropTypes, { node } from 'prop-types';
import { applyDrag } from '../../utils/Drag';

const propTypes = {
  items: PropTypes.arrayOf(node).isRequired,
  setItems: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
};

const defaultProps = {
  orientation: 'horizontal',
};

const DraggableContainer = ({ children, items, setItems, orientation }) => {
  function onDrop(dropResult) {
    const result = applyDrag(items, dropResult);
    setItems(result);
  }

  function getChildPayload(index) {
    return items[index];
  }

  return (
    <Container
      orientation={orientation}
      onDrop={onDrop}
      getChildPayload={getChildPayload}
      dragHandleSelector=".drag"
    >
      {children.map(child =>
        child.draggable === false ? (
          child
        ) : (
          <Draggable key={child.key}>{child}</Draggable>
        )
      )}
    </Container>
  );
};

DraggableContainer.propTypes = propTypes;
DraggableContainer.defaultProps = defaultProps;

export default DraggableContainer;
