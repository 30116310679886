import styled from 'styled-components';

const Container = styled.div`
  height: 20rem;
  display: flex;
  cursor: pointer;
  border-radius: 10px;

  h2 {
    margin-top: 0.5rem;
  }

  :hover {
    background-color: #fafafa;
    opacity: 0.95;
    transition: background-color 0.2s;
  }
`;

const Image = styled.img`
  border-radius: 20px;
  width: 50%;
  margin-right: 2rem;
  object-fit: cover;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.05);
    transition: transform 0.2s;
  }
`;

const Description = styled.p`
  color: #9a9a9a;
  margin-top: 0.5rem;
`;

export { Container, Image, Description };
