import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import Notification from '../Notification';

import { UserIcon, UserImage } from './styles';
import { useAuth } from '../../hooks/auth';

const propTypes = {
  userImage: PropTypes.shape({ url: PropTypes.string }),
};

const defaultProps = {
  userImage: '',
};

const ProfileUserImage = ({ userImage }) => {
  const { updateUserImage } = useAuth();
  const inputUploadImage = useRef();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (userImage) setImageUrl(userImage.url);
  }, [userImage]);

  async function uploadUserImage(file) {
    try {
      const data = new FormData();
      data.append('image', file);
      const response = await api.post('/images', data);
      Notification({
        type: Notification.types.SUCCESS,
        message: 'Imagem atualizada com sucesso',
      });
      updateUserImage(response.data.url);
    } catch (err) {
      throw new Error('Ocorreu um erro ao atualizar imagem');
    }
  }

  function handleImageClick() {
    inputUploadImage.current.click();
  }

  function handleInputChange(e) {
    if (e.target.files) {
      try {
        const image = e.target.files[0];
        uploadUserImage(image);

        const url = window.URL.createObjectURL(image);
        setImageUrl(url);
      } catch (err) {
        if (err.response) {
          Notification({
            type: Notification.types.ERROR,
            message: err.response.data.error,
          });
        }

        Notification({
          type: Notification.types.ERROR,
          message: 'Erro ao editar imagem',
        });
      }
    }
  }

  return (
    <>
      <input
        ref={inputUploadImage}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
      {imageUrl ? (
        <UserImage src={imageUrl} onClick={handleImageClick} />
      ) : (
        <UserIcon color="#9a9a9a" onClick={handleImageClick} />
      )}
    </>
  );
};

ProfileUserImage.propTypes = propTypes;
ProfileUserImage.defaultProps = defaultProps;

export default ProfileUserImage;
