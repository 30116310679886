import styled from 'styled-components';
import { FiSliders } from 'react-icons/fi';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ProjectsList = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  grid-template-columns: 33% 33% 33%;
  margin-left: 5rem;
  width: 100%;
  min-height: 50vh;
  padding-right: 6rem;

  @media (max-width: 1080px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    padding-right: 0;
    margin: 0 2rem;
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const ProjectFiltersContainer = styled.div`
  margin-bottom: 5rem;

  @media (max-width: 600px) {
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;

const FilterIcon = styled(FiSliders)`
  align-self: flex-start;
  margin-bottom: 3rem;
  cursor: pointer;

  @media (min-width: 600px) {
    display: none;
  }
`;

export {
  Container,
  ProjectsList,
  ProjectsContainer,
  ProjectFiltersContainer,
  FilterIcon,
};
