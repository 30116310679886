import HowWorksSection from '../../components/HowWorksSection';
import WelcomeSection from '../../components/WelcomeSection';
import ProjectsSection from '../../components/ProjectsSection';
import BecomeDesignerSection from '../../components/BecomeDesignerSection';

const Home = () => (
  <>
    <WelcomeSection />
    <HowWorksSection />
    <ProjectsSection />
    <BecomeDesignerSection />
  </>
);

export default Home;
