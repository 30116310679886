import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
`;

const Image = styled.img`
  margin-top: 5rem;
  max-width: 40rem;
  object-fit: cover;

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const Text = styled.p`
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
`;

export { Container, Image, Text };
