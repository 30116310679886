import PropTypes from 'prop-types';

import ReviewCard from '../ReviewCard';

import { ScrollContainer, NoReviews } from './styles';

const propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.objectOf),
  projectName: PropTypes.string,
};

const defaultProps = {
  reviews: [],
  projectName: '',
};

const ReviewsList = ({ reviews, projectName }) =>
  !reviews || reviews.length === 0 ? (
    <NoReviews> Ainda não há avaliações para este projeto.</NoReviews>
  ) : (
    <ScrollContainer vertical={false}>
      {reviews.map(r => (
        <ReviewCard key={r.id} review={{ ...r, projectName }} />
      ))}
    </ScrollContainer>
  );

ReviewsList.propTypes = propTypes;
ReviewsList.defaultProps = defaultProps;

export default ReviewsList;
