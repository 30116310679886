import { useEffect, useState } from 'react';

import BoughtProjectCard from '../../components/BoughtProjectCard';
import NoBuyFound from '../../components/NoBuyFound';
import Notification from '../../components/Notification';

import { Container, ProjectsList } from './styles';

const UserCourses = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setCourses([]);
      } catch (err) {
        Notification({
          message: 'Erro ao buscar projetos',
          type: Notification.types.ERROR,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  function renderProjectsList() {
    if (courses.length === 0) {
      return <NoBuyFound text="Você ainda não comprou nenhum curso" />;
    }

    return (
      <ProjectsList>
        {courses.map(p => (
          <BoughtProjectCard
            key={p.id}
            project={{ imageUrl: p.images[0].url, ...p }}
          />
        ))}
      </ProjectsList>
    );
  }

  return (
    <Container>
      <h1>Meus cursos</h1>
      {isLoading ? <div className="loader" /> : renderProjectsList()}
    </Container>
  );
};

export default UserCourses;
