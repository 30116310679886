import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const Header = styled.header`
  max-width: 1250px;
  display: flex;
  align-items: center;
  width: auto;
  margin: 4rem auto 0 auto;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`;

const LogoContainer = styled.img`
  width: 10rem;
  cursor: pointer;
  z-index: 3;
`;

const ExploreButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-left: 3.6rem;
  transition: background-color 0.5s;
  padding: 1rem;
  border-radius: 10px;

  :hover {
    background-color: #f5f5f5;
    transition: background-color 0.5s;
  }

  p {
    margin-left: 0.5rem;
  }
`;

const ButtonText = styled.p`
  font-weight: 700;
`;

const SearchInput = styled.input`
  border: 1px var(--primary-color) solid;
  border-radius: 12px;
  padding-left: 70px;
  padding-right: 20px;

  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 4.4rem 0 2rem;

  svg {
    position: relative;
    left: 50px;
    z-index: 1;
  }

  @media (max-width: 1080px) {
    margin: 0 2rem 0 0;
  }

  @media (max-width: 720px) {
    flex-basis: 100%;
    order: 2;
    margin-top: 3rem;
    margin: 3rem 0 0 -20px;
  }
`;

const Link = styled(RouterLink)`
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: transparent;
  margin-right: 2.5rem;
  transition: text-decoration 0.3s;
  text-align: center;

  :hover {
    text-decoration-color: var(--font-color);
    transition: text-decoration 0.5s;
  }

  :last-child {
    margin-right: 1.5rem;
  }
`;

const UserImage = styled.img`
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 6px;
  margin-left: 2rem;

  @media (max-width: 1080px) {
    margin-left: 1rem;
  }
`;

const Container = styled.div`
  width: 100%;
`;

export {
  Container,
  Header,
  LogoContainer,
  ExploreButton,
  ButtonText,
  SearchInput,
  InputContainer,
  Link,
  UserImage,
};
