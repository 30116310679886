import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15rem;
`;

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 4rem 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem 0 3rem 0;

  @media (max-width: 1080px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 620px) {
    grid-template-columns: 100%;
  }
`;

const Headline = styled.p`
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 2rem;

  strong {
    font-weight: 900;
  }
`;

export { Container, ProjectsContainer, Headline };
