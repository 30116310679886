import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';

import Button from '../Button';

import {
  Headline,
  ButtonsContainer,
  Content,
  modalStyles,
  CancelButton,
} from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const modalCloseTimeout = 500;

const AlertModal = ({ isOpen, onClose, onConfirm, title, text }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={modalStyles}
    closeTimeoutMS={modalCloseTimeout}
  >
    <Content>
      <FiAlertTriangle color="red" size={100} />
      <Headline>{title}</Headline>
      <p>{text}</p>
    </Content>
    <ButtonsContainer>
      <CancelButton text="Cancelar" color="red" onClick={onClose} />
      <Button text="Confirmar" color="red" primary onClick={onConfirm} />
    </ButtonsContainer>
  </Modal>
);

AlertModal.propTypes = propTypes;

AlertModal.closeTimeout = modalCloseTimeout;

export default AlertModal;
