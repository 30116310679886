import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FiPlay, FiStar } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import FormatCurrency from '../../utils/FormatCurrency';
import Button from '../../components/Button';
import FeaturedProjects from '../../components/FeaturedProjects';
import ProjectInfoDetails from '../../components/ProjectInfoDetails';
import ProjectReviews from '../../components/ProjectReviews';
import Notification from '../../components/Notification';
import ConfirmModal from '../../components/ConfirmModal';
import ReportModal from '../../components/ReportModal';
import CheckPasswordModal from '../../components/CheckPasswordModal';
import PolicyModal from '../../components/PolicyModal';

import {
  Container,
  HalfContainer,
  PicturesContainer,
  MainPicture,
  Picture,
  Description,
  Categories,
  Category,
  Author,
  PreviousPrice,
  Price,
  ScrollContainer,
  Rate,
  AuthorContainer,
  Title,
  Video,
  MainVideo,
  Report,
  ButtonsContainer,
  RatingContainer,
} from './styles';

function getYoutubePreviewImage(videoUrl) {
  const arr = videoUrl.split('/');
  const videoId = arr[arr.length - 1];

  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
}

const ProjectInfo = () => {
  const { isLoggedIn, userToken, loading: authLoading } = useAuth();
  const history = useHistory();
  const { id } = useParams();

  const [project, setProject] = useState({ designerUser: {} });
  const [mainPictureUrl, setMainPictureUrl] = useState('');
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isPolicyModalOpen, setPolicyModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showMainVideo, setShowMainVideo] = useState(false);
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isCheckPasswordModalOpen, setCheckPasswordModalOpen] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get(`/approved_project/${id}`);
          const formattedProject = {
            ...data,
            price: FormatCurrency(data.price),
            previousPrice: FormatCurrency(data.previousPrice),
          };
          setProject(formattedProject);
          setMainPictureUrl(data.images[0].url);
        } catch (err) {
          Notification({
            message: 'Erro ao buscar dados do projeto',
            type: Notification.types.ERROR,
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [id, authLoading]);

  function handleBuyButtonClick() {
    if (!isLoggedIn) {
      Notification({
        message: 'Você  precisa estar logado para realizar uma compra',
        type: Notification.types.ERROR,
      });
      return;
    }

    setConfirmModalOpen(true);
  }

  async function handleConfirmPurchase() {
    setConfirmModalOpen(false);
    setPolicyModalOpen(true);
  }

  async function handleConfirmPolicy() {
    setPolicyModalOpen(false);
    setCheckPasswordModalOpen(true);
  }

  async function handleSuccessPasswordChecking() {
    setCheckPasswordModalOpen(false);
    try {
      setLoading(true);
      await api.post(`/project/${project.id}/buy`);

      history.push('/success-purchase');
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.ERROR,
        });
      } else {
        Notification({
          message: 'Ocorreu um erro ao finalizar compra',
          type: Notification.types.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCutProjectButton() {
    Notification({
      message: 'Iniciando comunicação com Due Studio',
      type: Notification.types.INFO,
    });
    const request = new XMLHttpRequest();
    request.open(
      'GET',
      `http://127.0.0.1:3777/download/${project.id}/${userToken}`,
      true
    );
    request.onload = () => {
      Notification({
        message: request.responseText,
        type: Notification.types.INFO,
      });
    };
    request.onerror = () => {
      Notification({
        message:
          'Erro ao comunicar com Due Studio. Verifique se ele está aberto e tente novamente',
        type: Notification.types.ERROR,
        timeout: 10000,
      });
    };
    request.send();
  }

  function handleCheckPasswordModalClose() {
    setCheckPasswordModalOpen(false);
  }

  function handlePictureClick(url) {
    setShowMainVideo(false);
    setMainPictureUrl(url);
  }

  function handleVideoClick() {
    setShowMainVideo(true);
  }

  function handleReportClick() {
    setReportModalOpen(true);
  }

  function handleReportModalOpen() {
    setReportModalOpen(false);
  }

  if (isLoading) {
    return <div className="loader" />;
  }

  const hasRating = !!parseInt(project.averageRating, 10);

  return (
    <>
      <ReportModal
        isOpen={isReportModalOpen}
        onClose={handleReportModalOpen}
        projectId={id}
      />
      <CheckPasswordModal
        isOpen={isCheckPasswordModalOpen}
        onClose={handleCheckPasswordModalClose}
        onSuccess={handleSuccessPasswordChecking}
      />
      <ConfirmModal
        onClose={() => setConfirmModalOpen(false)}
        project={project}
        isOpen={isConfirmModalOpen}
        onConfirm={handleConfirmPurchase}
        title="Confirmar compra"
        text={`Você confirma a compra do projeto ${project.name} pelo valor de R$
        ${project.price}?`}
      />
      <PolicyModal
        onClose={() => setPolicyModalOpen(false)}
        isOpen={isPolicyModalOpen}
        onConfirm={handleConfirmPolicy}
      />
      <Container>
        <HalfContainer>
          <PicturesContainer>
            {showMainVideo ? (
              <MainVideo
                src={project.videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded demo video"
              />
            ) : (
              <MainPicture src={mainPictureUrl} />
            )}
            <ScrollContainer vertical={false}>
              {project.images &&
                project.images.map(i => (
                  <Picture
                    key={i.id}
                    src={i.url}
                    onClick={() => handlePictureClick(i.url)}
                  />
                ))}
              {project.videoUrl && (
                <Video
                  backgroundImage={getYoutubePreviewImage(project.videoUrl)}
                  onClick={handleVideoClick}
                >
                  <FiPlay size={40} color="#a9a9a9" fill="#a9a9a9" />
                </Video>
              )}
            </ScrollContainer>
          </PicturesContainer>
          <Description showOnMobile={false}>
            {project.longDescription}
          </Description>
        </HalfContainer>
        <HalfContainer>
          <Categories>
            {project.categories &&
              project.categories.map(c => (
                <Category key={c.id}>
                  <p>{c.name}</p>
                </Category>
              ))}
          </Categories>
          <Title>{project.name}</Title>
          <AuthorContainer>
            <Author
              to={{
                pathname: `/designer-projects/${project.designerUser.id}`,
                state: {
                  designer: project.designerUser,
                },
              }}
            >
              Criado por
              <strong>
                {project.designerUser ? ` ${project.designerUser.name}` : ' '}
              </strong>
            </Author>
            {hasRating && (
              <RatingContainer>
                <Rate>{project.averageRating}</Rate>
                <FiStar color="#F39200" fill="#F39200" size={19} />
              </RatingContainer>
            )}
            <Report onClick={handleReportClick}>Denunciar</Report>
          </AuthorContainer>
          <Description>{project.shortDescription}</Description>
          {project.previousPrice && (
            <PreviousPrice>{project.previousPrice}</PreviousPrice>
          )}
          <Price bought={project.bought}>{project.price}</Price>
          <ButtonsContainer>
            {project.bought ? (
              <Button
                text="Cortar projeto"
                primary
                color="#00ADEF"
                onClick={handleCutProjectButton}
              />
            ) : (
              <Button
                text="Comprar projeto"
                primary
                onClick={handleBuyButtonClick}
              />
            )}
            {project.userPremium && project.premium && (
              <Button
                text="Cortar projeto como PRO"
                primary
                color="#6342aa"
                onClick={handleCutProjectButton}
              />
            )}
          </ButtonsContainer>
          <ProjectInfoDetails
            materials={project.materials}
            videoUrl={project.tutorialUrl}
            projectPdf={project.projectPdf}
          />
          <Description showOnMobile>{project.longDescription}</Description>
        </HalfContainer>
        <ProjectReviews
          reviews={project.reviews}
          projectId={project.id}
          projectName={project.name}
        />
        <FeaturedProjects
          currentProjectId={id}
          title="Projetos que você também pode gostar"
        />
      </Container>
    </>
  );
};

export default ProjectInfo;
