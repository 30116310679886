import { useEffect, useState } from 'react';
import ProjectCard from '../../components/ProjectCard';
import api from '../../services/api';
import FormatCurrency from '../../utils/FormatCurrency';

import Container from './styles';

const FreeProjects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/free_projects');
        const formattedProjects = data.map(p => ({
          ...p,
          price: FormatCurrency(p.price),
          previousPrice: FormatCurrency(p.previousPrice),
        }));
        setProjects(formattedProjects);
      } catch (err) {
        Notification({
          type: Notification.types.ERROR,
          message: 'Erro ao buscar projeto',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  function renderProjectList() {
    if (projects.length === 0) {
      return (
        <h1 style={{ textAlign: 'center' }}>Ainda não há projetos gratuitos</h1>
      );
    }
    return (
      <Container>
        {projects.map(p => (
          <ProjectCard key={p.id} project={p} />
        ))}
      </Container>
    );
  }

  return isLoading ? <div className="loader" /> : renderProjectList();
};

export default FreeProjects;
