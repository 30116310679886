import styled from 'styled-components';

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.2rem;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;

  > button:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxError = styled.label`
  color: #f00;
  margin-top: 5px;
  font-weight: 500;
`;

const CheckBox = styled.input`
  margin-right: 1rem;
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '75%',
  },
};

export {
  Headline,
  ButtonsContainer,
  modalStyles,
  InputContainer,
  CheckBoxError,
  CheckBox,
};
