import PropTypes from 'prop-types';

import TransactionItem from '../TransactionItem';

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  transactions: [],
};

const TransactionList = ({ transactions }) => (
  <div>
    {transactions.map(t => (
      <TransactionItem key={t.id} transaction={t} />
    ))}
  </div>
);

TransactionList.propTypes = propTypes;
TransactionList.defaultProps = defaultProps;

export default TransactionList;
