import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import { Container, Main } from './styles';

const DefaultLayout = ({ children }) => (
  <>
    <Container>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  </>
);

export default DefaultLayout;
