import { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { getBankName } from '../../services/brasilapi';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import ProfileUserImage from '../../components/ProfileUserImage';

import { Container, InputContainer, ButtonsContainer } from './styles';

const accountTypes = [
  { value: '', label: 'Nenhum' },
  {
    label: 'Conta-corrente',
    value: 'Conta-corrente',
  },
  {
    label: 'Conta-poupança',
    value: 'Conta-poupança',
  },
];

const UserProfile = () => {
  const { loading: authLoading, userType } = useAuth();
  const formRef = useRef(null);
  const [bankName, setBankName] = useState('');
  const [userImage, setUserImage] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [windowWidth, setWindoWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindoWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  async function setAccountTypeField(value) {
    formRef.current.setFieldValue('accountType', { value, label: value });
  }

  async function setFormData(data) {
    const formData = data;
    if (data.birthDate) {
      const [date] = data.birthDate.split('T');
      formData.birthDate = date;
    }
    if (data.bankCode) {
      getBankName(data.bankCode).then(value => setBankName(value));
    }

    if (!formRef.current) return;
    formRef.current.setData(formData);
    setAccountTypeField(formData.accountType);
    setUserImage(data.image);
  }

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        try {
          const { data } = await api.get('/user');
          setFormData(data);
        } catch (err) {
          Notification({
            message: 'Erro ao buscar dados do usuário',
            type: Notification.types.ERROR,
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authLoading]);

  function cleanPasswordsFields() {
    formRef.current.setFieldValue('oldPassword', '');
    formRef.current.setFieldValue('password', '');
    formRef.current.setFieldValue('confirmPassword', '');
  }

  async function handleSubmit(data) {
    formRef.current.setErrors({});

    try {
      await api.put('/user', data);
      Notification({
        message: 'Perfil atualizado com sucesso',
        type: Notification.types.SUCCESS,
      });
      cleanPasswordsFields();
    } catch (err) {
      Notification({
        message: err.response.data.error,
        type: Notification.types.ERROR,
      });

      const { errors } = err.response.data;
      if (errors) {
        formRef.current.setErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleBankCodeChange(e) {
    setBankName(await getBankName(e.target.value));
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <Container>
        <h1>Meus dados</h1>
        <ProfileUserImage userImage={userImage} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <p>Dados pessoais</p>
          <Input name="name" label="Nome" />
          <Input name="email" label="E-mail" />
          <InputMask name="cpf" label="CPF" mask="999.999.999-99" />
          <Input
            type="date"
            name="birthDate"
            label="Data de nascimento"
            width={windowWidth > 800 ? 35 : null}
          />
          <Input name="description" label="Descrição" multiline />
          <p>Senha</p>
          <Input name="oldPassword" label="Senha antiga" type="password" />
          <Input name="password" label="Nova senha" type="password" />
          <Input
            name="confirmPassword"
            label="Confirmar senha"
            type="password"
          />
          {userType === 'designer' && (
            <>
              <p>Dados bancários</p>
              <Input name="recipientName" label="Nome do destinatário" />
              <InputContainer>
                <Input
                  type="number"
                  name="bankCode"
                  label="Código do banco"
                  width={windowWidth > 800 ? 45 : 100}
                  onChange={handleBankCodeChange}
                />
                <Input
                  name="bankName"
                  label="Nome do banco"
                  disabled
                  value={bankName}
                />
                <Input
                  name="operation"
                  label="Operação (Se aplicável)"
                  width={windowWidth > 800 ? 60 : 100}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  name="agency"
                  label="Agência"
                  width={windowWidth > 800 ? 50 : 100}
                />
                <Input
                  name="account"
                  label="Conta"
                  width={windowWidth > 800 ? 50 : 100}
                />
                <InputSelect
                  name="accountType"
                  label="Tipo de conta"
                  options={accountTypes}
                  width={windowWidth > 800 ? 50 : 100}
                />
              </InputContainer>
            </>
          )}
        </Form>
        <ButtonsContainer>
          <Button
            text="Salvar dados"
            primary
            onClick={() => formRef.current.submitForm()}
          />
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default UserProfile;
