import PropTypes from 'prop-types';
import { FaUser, FaStar } from 'react-icons/fa';

import {
  Container,
  UserInfo,
  UserImage,
  UserName,
  ProjectName,
  Review,
  RateContainer,
} from './styles';

const propTypes = {
  review: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({ url: PropTypes.string }).isRequired,
    }).isRequired,
    projectName: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
    review: PropTypes.string,
  }).isRequired,
};

const ReviewCard = ({ review: { user, rate, review, projectName } }) => (
  <Container>
    <UserInfo>
      {user.image ? (
        <UserImage src={user.image.url} alt="Imagem do usuário" />
      ) : (
        <FaUser
          color="#9a9a9a"
          size={80}
          style={{ padding: 5, marginRight: 10 }}
        />
      )}
      <div>
        <UserName>{user.name}</UserName>
        <RateContainer>
          {Array.from({ length: rate }).map((_, i) => (
            <FaStar key={`${i + 1}`} color="#F39200" size={18} />
          ))}
        </RateContainer>
      </div>
    </UserInfo>
    <ProjectName>{projectName}</ProjectName>
    <Review>{review || ''}</Review>
  </Container>
);

ReviewCard.propTypes = propTypes;

export default ReviewCard;
