import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';

import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [cookies, setCookies, removeCookies] = useCookies();

  function cleanCookies() {
    removeCookies('token');
    removeCookies('userImage');
    removeCookies('userType');
    removeCookies('userName');
  }

  function formatName(fullName) {
    const splittedName = fullName.split(' ');
    if (splittedName[0] >= 6) {
      return splittedName[0];
    }

    return `${splittedName[0]} ${splittedName[1] || ''}`.trim();
  }

  useEffect(() => {
    (() => {
      try {
        if (cookies.token && cookies.userType) {
          api.defaults.headers.authorization = `Bearer ${cookies.token}`;
        }
      } catch (err) {
        cleanCookies();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const signIn = useCallback(
    ({ newToken, newUserImage, newUserType, newUserName }) => {
      api.defaults.headers.authorization = `Bearer ${newToken}`;
      setCookies('token', newToken, { maxAge: 43200 });
      setCookies('userImage', newUserImage, { maxAge: 43200 });
      setCookies('userName', formatName(newUserName), { maxAge: 43200 });
      if (newUserType === 'Admin') {
        setCookies('userType', 'designer', { maxAge: 43200 });
      } else {
        setCookies('userType', newUserType.toLowerCase(), { maxAge: 43200 });
      }
    },
    []
  );

  const signOut = useCallback(() => {
    cleanCookies();
  }, []);

  const updateUserImage = useCallback(imageUrl => {
    removeCookies('userImage');
    setCookies('userImage', imageUrl);
  });

  return (
    <AuthContext.Provider
      value={{
        userImage: cookies.userImage,
        isLoggedIn: cookies.token && cookies.userType,
        userType: cookies.userType,
        userName: cookies.userName,
        userToken: cookies.token,
        loading,
        signIn,
        signOut,
        updateUserImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
