import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import DesignerSideMenu from '../../../components/DesignerSideMenu';
import CutterSideMenu from '../../../components/CutterSideMenu';

import { Container, Main, MainContainer, MenuContainer } from './styles';

const propTypes = {
  type: PropTypes.string.isRequired,
};

const DefaultLayout = ({ children, type }) => (
  <>
    <Container>
      <Header />
      <MainContainer>
        <MenuContainer>
          {type === 'designer' ? <DesignerSideMenu /> : <CutterSideMenu />}
        </MenuContainer>
        <Main>{children}</Main>
      </MainContainer>
      <Footer />
    </Container>
  </>
);

DefaultLayout.propTypes = propTypes;

export default DefaultLayout;
