import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { BsFillStarFill as StarFill, BsStar as Star } from 'react-icons/bs';

import Button from '../Button';

import {
  Headline,
  ButtonsContainer,
  Input,
  modalStyles,
  StarsContainer,
  Error,
} from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ReviewModal = ({ onClose, onConfirm, isOpen }) => {
  const [rate, setRate] = useState(0);
  const [review, setReview] = useState('');
  const [error, setError] = useState('');

  function resetFields() {
    setError('');
    setRate(0);
    setReview('');
  }

  function handleConfirmButtonClick() {
    setError('');
    if (rate === 0) {
      setError('Informe uma nota');
      return;
    }

    onConfirm({ rate, review });
    resetFields();
  }

  function handleClose() {
    resetFields();
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Deixe sua opinião"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Headline>Deixe sua opinião</Headline>
      <p>Deixe um comentário do que você achou desse projeto.</p>
      <Input
        type="text"
        multiline
        onChange={e => setReview(e.target.value)}
        value={review}
      />
      <StarsContainer>
        {rate >= 1 ? (
          <StarFill color="#fcbf17" size={28} onClick={() => setRate(1)} />
        ) : (
          <Star color="#fcbf17" size={28} onClick={() => setRate(1)} />
        )}
        {rate >= 2 ? (
          <StarFill color="#fcbf17" size={28} onClick={() => setRate(2)} />
        ) : (
          <Star color="#fcbf17" size={28} onClick={() => setRate(2)} />
        )}
        {rate >= 3 ? (
          <StarFill color="#fcbf17" size={28} onClick={() => setRate(3)} />
        ) : (
          <Star color="#fcbf17" size={28} onClick={() => setRate(3)} />
        )}
        {rate >= 4 ? (
          <StarFill color="#fcbf17" size={28} onClick={() => setRate(4)} />
        ) : (
          <Star color="#fcbf17" size={28} onClick={() => setRate(4)} />
        )}
        {rate >= 5 ? (
          <StarFill color="#fcbf17" size={28} onClick={() => setRate(5)} />
        ) : (
          <Star color="#fcbf17" size={28} onClick={() => setRate(5)} />
        )}
        {error && <Error>{error}</Error>}
      </StarsContainer>
      <ButtonsContainer>
        <Button text="Cancelar" onClick={handleClose} />
        <Button text="Enviar" primary onClick={handleConfirmButtonClick} />
      </ButtonsContainer>
    </Modal>
  );
};

ReviewModal.propTypes = propTypes;

export default ReviewModal;
