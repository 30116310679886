import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import FormatCurrency from '../../utils/FormatCurrency';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import ProjectInfoDetails from '../../components/ProjectInfoDetails';

import {
  Container,
  HalfContainer,
  PicturesContainer,
  MainPicture,
  Picture,
  Description,
  Categories,
  Category,
  PreviousPrice,
  Price,
  ScrollContainer,
  ButtonsContainer,
} from './styles';

const ProjectInfo = () => {
  const { loading: authLoading } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const { userToken } = useAuth();

  const [project, setProject] = useState({});
  const [mainPictureUrl, setMainPictureUrl] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isPaused, setPaused] = useState(false);

  async function fetchProject() {
    try {
      setLoading(true);
      const { data } = await api.get(`/project/${id}`);
      const formattedProject = {
        ...data,
        price: FormatCurrency(data.price),
        previousPrice: FormatCurrency(data.previousPrice),
      };
      setProject(formattedProject);
      setMainPictureUrl(data.images[0].url);
      setPaused(data.paused);
    } catch (err) {
      Notification({
        message: 'Erro ao buscar dados do projeto',
        type: Notification.types.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!authLoading) {
      fetchProject();
    }
  }, [id, authLoading]);

  function handleEditButtonClick() {
    history.push(`/designer/projects/${id}/edit`);
  }

  function handleCutProjectButton() {
    Notification({
      message: 'Iniciando comunicação com Due Studio',
      type: Notification.types.INFO,
    });
    const request = new XMLHttpRequest();
    request.open(
      'GET',
      `http://127.0.0.1:3777/download/${project.id}/${userToken}`,
      true
    );
    request.onload = () => {
      Notification({
        message: request.responseText,
        type: Notification.types.INFO,
      });
    };
    request.onerror = () => {
      Notification({
        message:
          'Erro ao comunicar com Due Studio. Verifique se ele está aberto e tente novamente',
        type: Notification.types.ERROR,
        timeout: 10000,
      });
    };
    request.send();
  }

  async function handlePauseProjectClick() {
    try {
      setLoading(true);
      await api.put(`/project/${project.id}/pause`);
      Notification({
        message: 'Venda do projeto pausada com sucesso',
        type: Notification.types.SUCCESS,
      });
      fetchProject();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.SUCCESS,
        });
        return;
      }

      Notification({
        message: 'Erro ao pausar venda do projeto',
        type: Notification.types.SUCCESS,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleUnpauseProjectClick() {
    try {
      setLoading(true);
      await api.put(`/project/${project.id}/unpause`);
      Notification({
        message: 'Venda do projeto retomada com sucesso',
        type: Notification.types.SUCCESS,
      });
      fetchProject();
    } catch (err) {
      if (err.response) {
        Notification({
          message: err.response.data.error,
          type: Notification.types.SUCCESS,
        });
        return;
      }

      Notification({
        message: 'Erro ao retomar venda do projeto',
        type: Notification.types.SUCCESS,
      });
    } finally {
      setLoading(false);
    }
  }

  return isLoading ? (
    <div className="loader" />
  ) : (
    <Container>
      <HalfContainer>
        <PicturesContainer>
          <MainPicture src={mainPictureUrl} />
          <ScrollContainer vertical={false}>
            {project.images &&
              project.images.map(i => (
                <Picture
                  key={i.id}
                  src={i.url}
                  onClick={() => setMainPictureUrl(i.url)}
                />
              ))}
          </ScrollContainer>
        </PicturesContainer>
        <Description>{project.longDescription}</Description>
      </HalfContainer>
      <HalfContainer>
        <Categories>
          {project.categories &&
            project.categories.map(c => (
              <Category key={c.id}>
                <p>{c.name}</p>
              </Category>
            ))}
        </Categories>
        <h1>{project.name}</h1>
        <Description>{project.shortDescription}</Description>
        {project.previousPrice && (
          <PreviousPrice>{project.previousPrice}</PreviousPrice>
        )}
        <Price>{project.price}</Price>
        <ButtonsContainer>
          <Button
            text="Editar projeto"
            primary
            minWidth={180}
            onClick={handleEditButtonClick}
          />
          <Button
            text="Cortar projeto"
            primary
            color="#00ADEF"
            minWidth={180}
            onClick={handleCutProjectButton}
          />
          {isPaused ? (
            <Button
              text="Retomar venda"
              minWidth={180}
              onClick={handleUnpauseProjectClick}
            />
          ) : (
            <Button
              text="Pausar venda"
              minWidth={180}
              onClick={handlePauseProjectClick}
            />
          )}
        </ButtonsContainer>
        <ProjectInfoDetails
          materials={project.materials}
          videoUrl={project.tutorialUrl}
          projectPdf={project.projectPdf}
        />
      </HalfContainer>
    </Container>
  );
};

export default ProjectInfo;
