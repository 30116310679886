import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

import Container from './styles';

const propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pagesCount: PropTypes.number.isRequired,
  initialPage: PropTypes.number,
};

const defaultProps = {
  initialPage: 1,
};

const Paginator = ({ onPageChange, pagesCount, initialPage }) => (
  <Container>
    <ReactPaginate
      pageCount={pagesCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      previousLabel="Anterior"
      nextLabel="Próxima"
      initialPage={initialPage < 1 ? 0 : initialPage - 1} // Set first page if initialPage is less than 1
      containerClassName="container"
      pageClassName="item"
      previousClassName="item"
      nextClassName="item"
      pageLinkClassName="link"
      previousLinkClassName="link"
      nextLinkClassName="link"
      activeClassName="active"
      activeLinkClassName="active"
      onPageChange={({ selected }) => onPageChange(selected + 1)}
    />
  </Container>
);

Paginator.propTypes = propTypes;
Paginator.defaultProps = defaultProps;

export default Paginator;
