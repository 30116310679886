import PropTypes from 'prop-types';

import NoDataSvg from '../../assets/svg/no-data.svg';
import { Container, Image, Text } from './styles';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const NoSalesFound = ({ text }) => (
  <Container>
    <Text>{text}</Text>
    <Image src={NoDataSvg} alt="Não encontrado" />
  </Container>
);

NoSalesFound.propTypes = propTypes;

export default NoSalesFound;
