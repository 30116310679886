import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FiUser,
  FiShoppingBag,
  FiLogOut,
  FiBook,
  FiGift,
} from 'react-icons/fi';
import { BiWallet } from 'react-icons/bi';

import { useAuth } from '../../hooks/auth';
import ProModal from '../ProModal';
import CancelProModal from '../CancelProModal';
import Notification from '../Notification';

import { Container, MenuItem } from './styles';
import Button from '../Button';
import api from '../../services/api';

const CutterSideMenu = () => {
  const { signOut, loading } = useAuth();
  const [proModalOpen, setProModal] = useState(false);
  const [cancelProModalOpen, setCancelProModal] = useState(false);
  const [userPremium, setUserPremium] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!loading) {
          const response = await api.get('/user_plan');
          setUserPremium(response.data.premium);
        }
        setLoading(false);
      } catch (err) {
        Notification({
          message: 'Erro ao carregar dados do usuário',
          type: Notification.types.ERROR,
        });
      }
    })();
  }, [loading]);

  function handleProButtonClick() {
    setProModal(true);
  }

  function handleProModalClose() {
    setProModal(false);
  }

  function handleCancelProButtonClick() {
    setCancelProModal(true);
  }

  function handleCancelProModalClose() {
    setCancelProModal(false);
  }

  function renderProButton() {
    if (userPremium) {
      return (
        <Button
          text="Cancelar assinatura"
          onClick={handleCancelProButtonClick}
          withIcon="star"
        />
      );
    }

    return (
      <Button
        text="Me tornar PRO"
        primary
        onClick={handleProButtonClick}
        withIcon="star"
      />
    );
  }

  return (
    <>
      <ProModal isOpen={proModalOpen} onClose={handleProModalClose} />
      <CancelProModal
        isOpen={cancelProModalOpen}
        onClose={handleCancelProModalClose}
      />
      <Container>
        <h1>Minha conta</h1>
        <ul>
          <MenuItem>
            <FiUser color="#00ADEF" size={20} />
            <Link to="/cutter/profile">Meus dados</Link>
          </MenuItem>
          <MenuItem>
            <FiShoppingBag color="#00ADEF" size={19} />
            <Link to="/cutter/projects">Meus projetos</Link>
          </MenuItem>
          <MenuItem>
            <FiBook color="#00ADEF" size={19} />
            <Link to="/cutter/courses">Meus cursos</Link>
          </MenuItem>
          <MenuItem>
            <BiWallet color="#00ADEF" size={20} />
            <Link to="/cutter/wallet">Minha carteira</Link>
          </MenuItem>
          <MenuItem>
            <FiGift color="#00ADEF" size={20} />
            <Link to="/cutter/indicate-a-friend">Indique um amigo</Link>
          </MenuItem>
          <MenuItem>
            <FiLogOut color="#00ADEF" size={20} />
            <Link to="/" onClick={signOut}>
              Sair do sistema
            </Link>
          </MenuItem>
        </ul>
        {!isLoading && renderProButton()}
      </Container>
    </>
  );
};

export default CutterSideMenu;
