import { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoIosMove, IoMdCloseCircle } from 'react-icons/io';

import api from '../../services/api';
// import { validateImageDimensions } from '../../utils/Image';
import { useAuth } from '../../hooks/auth';
import Input from '../../components/Input';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import InputCheckBox from '../../components/InputCheckBox';
import NewProjectContractModal from '../../components/NewProjectContractModal';
import DraggableContainer from '../../components/DraggableContainer';

import {
  Container,
  InputContainer,
  ImagesContainer,
  AddFileInput,
  AddImageButton,
  Image,
  ImageContainer,
  InputSmall,
} from './styles';

const NewProject = () => {
  const { loading: authLoading } = useAuth();
  const inputUploadImageRef = useRef(null);
  const inputUploadProjectFileRef = useRef(null);
  const inputUploadProjectPdfRef = useRef(null);
  const formRef = useRef(null);

  const [isDesignerPremium, setDesignerPremium] = useState(false);
  const [isContractModalOpen, setContractModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState('');
  const [pdf, setPdf] = useState('');
  const [materialsOptions, setMaterialsOptions] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [windowWidth, setWindoWidth] = useState(0);

  const [designerProfit, setDesignerProfit] = useState(0);
  const [isLoadingSaleTax, setLoadingSaleTax] = useState(true);
  const [saleTax, setSaleTax] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindoWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      if (authLoading) return;
      try {
        const response = await api.get('/sale_tax');
        setSaleTax(response.data.value);
        setLoadingSaleTax(false);
      } catch (err) {
        Notification({
          message: 'Erro ao buscar taxa sobre vendas',
          type: Notification.types.ERROR,
        });
      }
    })();
  }, [authLoading]);

  function formatToSelectOption(arr) {
    return arr.map(i => ({ label: i.name, value: i.id }));
  }

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/project_categories');
      setCategoriesOptions(formatToSelectOption(data));
    })();

    (async () => {
      const { data } = await api.get('/materials');
      setMaterialsOptions(formatToSelectOption(data));
    })();
  }, []);

  useEffect(() => {
    if (!authLoading) {
      (async () => {
        const {
          data: { designerPremium },
        } = await api.get('/user');

        setDesignerPremium(designerPremium);
      })();
    }
  }, [authLoading]);

  function handleUploadImageButtonClick() {
    inputUploadImageRef.current.click();
  }

  function handleInputFileChange(e) {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  function handleUploadProjectFileButtonClick(e) {
    e.preventDefault();
    inputUploadProjectFileRef.current.click();
  }

  async function validateImages(files) {
    const maxSize = 4 * 1024 * 1024; // 4MB

    const validatedImages = await Promise.all(
      files.map(async f => {
        const validDimensions = true; /* await validateImageDimensions(f, 500, 500); */

        const validSize = f.size < maxSize;

        return { file: f, validDimensions, validSize };
      })
    );

    return validatedImages;
  }

  async function filterImages(files) {
    const validatedImages = await validateImages(files);

    const filteredImages = validatedImages.filter(f => {
      if (!f.validSize) {
        Notification({
          message: 'Tamanho máximo permitido é de 2MB',
          title: 'Arquivo muito grande',
          type: Notification.types.ERROR,
        });
        return false;
      }

      /* if (!f.validDimensions) {
        Notification({
          message: 'A imagem precisa ter 500px de largura por 500px de altura',
          title: 'Dimensões incorretas',
          type: Notification.types.ERROR,
        });
        return false;
      } */

      return true;
    });

    return filteredImages.map(f => f.file);
  }

  function generateImagesIds(arr) {
    return arr.map(i => {
      i.id = Math.random();
      return i;
    });
  }

  async function handleImageInputChange(e) {
    const { files } = e.target;
    if (files) {
      const filteredImages = await filterImages([...files]);
      const imagesWithId = generateImagesIds(filteredImages);
      setImages([...images, ...imagesWithId]);

      inputUploadImageRef.current.value = '';
    }
  }

  function validateForm(data) {
    if (!data.categories || data.categories.length === 0) {
      formRef.current.setFieldError(
        'categories',
        'Selecione pelo menos uma categoria'
      );
      return false;
    }

    if (!data.materials || data.materials.length === 0) {
      formRef.current.setFieldError(
        'materials',
        'Selecione pelo menos um material'
      );
      return false;
    }

    if (images.length === 0) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione pelo menos uma imagem',
      });
      return false;
    }

    if (!file) {
      Notification({
        type: Notification.types.ERROR,
        message: 'Selecione o arquivo do projeto',
      });

      return false;
    }

    return true;
  }

  function clearForm() {
    formRef.current.reset();
    setPdf({ name: '' });
    inputUploadProjectPdfRef.current.value = '';
    setFile({ name: '' });
    inputUploadProjectFileRef.current.value = '';
    setImages([]);
    inputUploadImageRef.current.value = '';
  }

  async function createNewProject() {
    try {
      setLoading(true);
      formRef.current.setErrors({});

      const projectData = formRef.current.getData();
      projectData.materials = projectData.materials.map(m => ({ id: m.value }));
      projectData.categories = projectData.categories.map(m => ({
        id: m.value,
      }));
      projectData.featuredImage = images[0].name;

      const formData = new FormData();
      formData.append('project', JSON.stringify(projectData));
      formData.append('projectFile', file);
      formData.append('projectPdf', pdf);
      images.forEach(i => formData.append('projectImages', i));

      await api.post('/projects', formData);

      Notification({
        type: Notification.types.SUCCESS,
        message: 'Projeto criado com sucesso',
      });
      clearForm();
    } catch (err) {
      if (err.response && err.response.data.errors) {
        formRef.current.setErrors(err.response.data.errors);
        return;
      }

      const error = err.response
        ? err.response.data.error
        : 'Erro ao enviar formulário';

      Notification({ message: error, type: Notification.types.ERROR });
    } finally {
      setLoading(false);
    }
  }

  function handleFormSubmit(data) {
    formRef.current.setErrors({});
    if (validateForm(data)) {
      setContractModalOpen(true);
    }
  }

  function handleDeleteImageClick(i) {
    const filteredImages = images.filter(image => image.name !== i.name);
    setImages(filteredImages);
  }

  function handleUploadProjectPdfButtonClick(e) {
    e.preventDefault();
    inputUploadProjectPdfRef.current.click();
  }

  function handleInputPdfChange(e) {
    if (e.target.files) {
      setPdf(e.target.files[0]);
    }
  }

  function handleConfirmContractModal() {
    setContractModalOpen(false);
    createNewProject();
  }

  function handleCloseContractModal() {
    setContractModalOpen(false);
  }

  function handleProjectPriceInputChange(e) {
    if (Number.isNaN(e.target.value)) {
      setDesignerProfit(0);
      return;
    }

    const discounted = +e.target.value * (saleTax / 100);
    const profit = +e.target.value - discounted;
    setDesignerProfit(profit.toFixed(2));
  }

  return (
    <>
      {isLoading && <div className="loader" />}
      <NewProjectContractModal
        isOpen={isContractModalOpen}
        onClose={handleCloseContractModal}
        onConfirm={handleConfirmContractModal}
      />
      <Container>
        <h1>Novo projeto</h1>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Input name="name" label="Nome do projeto" />
          <Input
            type="text"
            name="shortDescription"
            label="Descrição curta (Máx 150 caracteres)"
            maxLength="150"
          />
          <ImagesContainer vertical={false} ignoreElements=".drag">
            <div>
              <AddFileInput
                ref={inputUploadImageRef}
                type="file"
                multiple="multiple"
                onChange={handleImageInputChange}
              />
              <AddImageButton
                title="Adicionar imagem"
                type="button"
                onClick={handleUploadImageButtonClick}
              >
                <AiOutlinePlus size={50} color="#BDBDBD" />
              </AddImageButton>
              <p className="featured-image">Imagem destaque</p>
            </div>
            <DraggableContainer items={images} setItems={setImages}>
              {images &&
                images.map(i => (
                  <ImageContainer key={i.id}>
                    <Image
                      src={window.URL.createObjectURL(i)}
                      alt="Imagem do projeto"
                      draggable="false"
                    />
                    <IoIosMove size={24} className="drag" color="#00ADEF" />
                    <IoMdCloseCircle
                      size={22}
                      className="delete"
                      color="#00ADEF"
                      onClick={() => handleDeleteImageClick(i)}
                    />
                  </ImageContainer>
                ))}
            </DraggableContainer>
          </ImagesContainer>
          <InputSmall>
            Tamanho máximo: 4MB. Formatos aceitos: PNG, JPG e JPEG.
          </InputSmall>
          <Input
            name="videoUrl"
            label="URL do vídeo de demonstração do projeto"
          />
          <Input
            step=".01"
            type="number"
            name="price"
            label="Preço"
            width={windowWidth > 800 ? 30 : 100}
            onChange={handleProjectPriceInputChange}
          />
          {!isLoadingSaleTax && (
            <InputSmall>
              Você receberá R${designerProfit} por venda. (Taxa de {saleTax}%)
            </InputSmall>
          )}
          <InputSelect
            name="categories"
            options={categoriesOptions}
            label="Categorias"
            placeholder="Selecione as categorias"
            noOptionsMessage="Sem categorias"
            isMulti
            isLoading={categoriesOptions === null}
          />
          <InputSelect
            name="materials"
            options={materialsOptions}
            label="Materiais"
            placeholder="Selecione os materiais"
            noOptionsMessage="Sem materiais"
            isMulti
            isLoading={materialsOptions === null}
          />

          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectFileRef}
              type="file"
              onChange={handleInputFileChange}
            />
            <Input
              name="projectFile"
              label="Arquivo do projeto"
              disabled
              value={file.name}
            />
            <Button
              text="Upload"
              onClick={handleUploadProjectFileButtonClick}
            />
          </InputContainer>
          <InputSmall>Tamanho máximo: 2MB. Formato aceitos: SVG</InputSmall>
          <InputContainer>
            <AddFileInput
              ref={inputUploadProjectPdfRef}
              type="file"
              onChange={handleInputPdfChange}
            />
            <Input
              name="projectPdf"
              label="Arquivo PDF"
              disabled
              value={pdf.name}
            />
            <Button text="Upload" onClick={handleUploadProjectPdfButtonClick} />
          </InputContainer>
          <InputSmall>Tamanho máximo: 4MB.</InputSmall>

          <Input name="tutorialUrl" label="URL do vídeo tutorial" />
          <Input name="longDescription" label="Descrição longa" multiline />
          {isDesignerPremium && (
            <InputCheckBox type="checkbox" name="premium" label="Projeto PRO" />
          )}
          <Button
            type="submit"
            text="Publicar projeto"
            primary
            disabled={isLoading}
          />
        </Form>
      </Container>
    </>
  );
};

export default NewProject;
