import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.footer`
  min-height: 26rem;
  max-height: 26rem;
  background-color: var(--primary-color);
  padding: 1.5rem 7rem 1.5rem 8rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  width: calc(100% + 8rem);
  margin-left: -4rem;

  @media (max-width: 800px) {
    min-height: 45rem;
    max-height: 45rem;
    padding: 1.5rem 3rem 1.5rem 3rem;
  }
`;

const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 13rem;
  margin-right: 1rem;
`;

const Logo = styled.img`
  margin: 0 1rem;
`;

const Copyright = styled.p`
  opacity: 0.6;
  font-size: 1.4rem;
  color: white;
  font-weight: 700;
  text-align: center;

  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 2.5rem;

  @media (max-width: 800px) {
    margin: 5rem 1rem;
  }
`;

const SafePaymentText = styled(Copyright)`
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const PaymentMethodsContainer = styled.div`
  align-self: flex-start;

  @media (max-width: 800px) {
    align-self: flex-end;
  }
`;

const PaymentMethodSvg = styled.img`
  margin: 0 1rem;
`;

const FooterContent = styled.div`
  @media (max-width: 800px) {
    > div {
      flex-direction: column-reverse;
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export {
  Container,
  FooterDiv,
  SocialIconsContainer,
  Logo,
  Copyright,
  FooterLink,
  PaymentMethodsContainer,
  SafePaymentText,
  PaymentMethodSvg,
  FooterHeader,
  FooterContent,
  LinksContainer,
};
