import { Link, useHistory } from 'react-router-dom';
import {
  FiUser,
  FiShoppingBag,
  FiLayers,
  FiDollarSign,
  FiLogOut,
  FiGift,
} from 'react-icons/fi';
import { BiWallet } from 'react-icons/bi';

import Button from '../Button';
import { useAuth } from '../../hooks/auth';
import { Container, MenuItem, Menu } from './styles';

const DesignerSideMenu = () => {
  const history = useHistory();
  const { signOut } = useAuth();

  function handleNewProjectButtonClick() {
    history.push('/designer/new-project');
  }

  return (
    <Container>
      <h1>Minha conta</h1>
      <Menu>
        <MenuItem>
          <FiLayers color="#00ADEF" size={20} />
          <Link to="/designer/projects">Meus projetos</Link>
        </MenuItem>
        <MenuItem>
          <FiDollarSign color="#00ADEF" size={19} />
          <Link to="/designer/remuneration">Remuneração</Link>
        </MenuItem>
        <MenuItem>
          <BiWallet color="#00ADEF" size={20} />
          <Link to="/designer/wallet">Minha carteira</Link>
        </MenuItem>
        <MenuItem>
          <FiUser color="#00ADEF" size={20} />
          <Link to="/designer/profile">Meus dados</Link>
        </MenuItem>
        <MenuItem>
          <FiShoppingBag color="#00ADEF" size={20} />
          <Link to="/designer/buys">Minhas compras</Link>
        </MenuItem>
        <MenuItem>
          <FiGift color="#00ADEF" size={20} />
          <Link to="/designer/indicate-a-friend">Indique um amigo</Link>
        </MenuItem>
        <MenuItem>
          <FiLogOut color="#00ADEF" size={20} />
          <Link to="/" onClick={signOut}>
            Sair do sistema
          </Link>
        </MenuItem>
      </Menu>
      <Button
        text="Novo Projeto"
        primary
        onClick={handleNewProjectButtonClick}
        withIcon="plus"
      />
    </Container>
  );
};

export default DesignerSideMenu;
