import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { CustomInput, CustomTextArea, Label, InputContainer } from './styles';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.number,
  multiline: PropTypes.bool,
};

const defaultProps = {
  width: 100,
  multiline: false,
};

const Input = ({ name, label, width, multiline, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <InputContainer width={width}>
      {label && <Label htmlFor={fieldName}>{label}</Label>}

      {multiline ? (
        <CustomTextArea
          ref={inputRef}
          id={fieldName}
          defaultValue={rest.value ? null : defaultValue}
          rows="5"
          {...rest}
        />
      ) : (
        <CustomInput
          ref={inputRef}
          id={fieldName}
          defaultValue={rest.value ? null : defaultValue}
          width={100}
          {...rest}
        />
      )}

      {error && (
        <span style={{ color: '#f00', marginTop: 5, fontWeight: 500 }}>
          {error}
        </span>
      )}
    </InputContainer>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
