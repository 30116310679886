import styled from 'styled-components';
import Scroll from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-wrap: wrap;
`;

const HalfContainer = styled.div`
  width: 48%;

  @media (max-width: 720px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const PicturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 720px) {
    margin-bottom: 5rem;
  }
`;

const MainPicture = styled.img`
  width: 100%;
  height: 50rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
`;

const Picture = styled.img`
  width: 24%;
  height: 15rem;
  border-radius: 20px;
  object-fit: cover;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  :not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;

  button:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const Video = styled.div`
  width: 24%;
  height: 15rem;
  border-radius: 20px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: contain;
  border: 1px solid #a9a9a9;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

const MainVideo = styled.iframe`
  width: 100%;
  height: 50rem;
  border-radius: 20px;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #9a9a9a;
  margin-top: 3rem;
  margin-bottom: 5rem;
  line-height: 2.2rem;
  display: ${({ showOnMobile }) => (showOnMobile ? 'none' : 'block')};

  @media (max-width: 720px) {
    display: ${({ showOnMobile }) => {
      if (showOnMobile === undefined) {
        return 'block';
      }

      return showOnMobile ? 'block' : 'none';
    }};
  }
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  white-space: nowrap;
`;

const Category = styled.div`
  background: rgba(247, 182, 0, 0.12);
  border-radius: 6px;
  padding: 0.4rem 1.2rem;
  margin: 0.2rem 0;

  :not(:last-child) {
    margin-right: 1rem;
  }

  p {
    color: var(--accent-color);
  }
`;

const Author = styled(Link)`
  font-size: 1.2rem;
  color: #9a9a9a;
  text-decoration: none;

  :hover {
    strong {
      text-decoration: underline;
    }
  }
`;

const Price = styled.p`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: ${({ bought }) =>
    bought ? 'var(--primary-color)' : 'var(--accent-color)'};
`;

const PreviousPrice = styled.p`
  color: #a9a9a9;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: line-through;
`;

const Title = styled.h1`
  font-size: 3.6rem;
`;

const ScrollContainer = styled(Scroll)`
  width: 100%;
  white-space: nowrap;
`;

const Rate = styled.p`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0.2rem 0 1rem;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 0.4rem;

  svg {
    align-self: center;
  }
`;

const Report = styled.p`
  color: #ff3333;
  font-size: 1.2rem;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
    background-color: #ff3333;
    color: white;
    transition: all 0.3s;
  }
`;

const RatingContainer = styled.div`
  display: flex;
`;

export {
  Container,
  HalfContainer,
  PicturesContainer,
  MainPicture,
  Picture,
  Description,
  Categories,
  Category,
  Author,
  PreviousPrice,
  Price,
  ScrollContainer,
  Rate,
  AuthorContainer,
  Title,
  Video,
  MainVideo,
  Report,
  ButtonsContainer,
  RatingContainer,
};
