import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiCompass, FiSearch } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';
import LogoSvg from '../../assets/svg/logo-blue.svg';
import Button from '../Button';
import {
  Container,
  Header,
  LogoContainer,
  ExploreButton,
  ButtonText,
  SearchInput,
  InputContainer,
  Link,
  UserImage,
} from './styles';
import BurguerMenu from '../BurguerMenu';
import { useFilters } from '../../hooks/projectFilters';

const HeaderComponent = () => {
  const { setSearchString, searchString } = useFilters();
  const history = useHistory();
  const location = useLocation();
  const { resetFilters } = useFilters();
  const { isLoggedIn, userImage, userType, userName } = useAuth();

  const [searchValue, setSearchValue] = useState('');
  const [windowWidth, setWindowSize] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setSearchValue(searchString);
  }, [searchString]);

  function handleLoginButtonClick() {
    history.push('/login');
  }

  function handleExploreButtonClick() {
    if (location.pathname !== '/projects') {
      history.push('/projects');
      resetFilters();
    }
  }

  function handleLogoClick() {
    history.push('/');
  }

  function handleInputKeyUp(e) {
    if (e.key === 'Enter') {
      setSearchString(e.target.value);
      if (location.pathname !== '/projects') {
        history.push(`/projects`);
      }
    }
  }

  function handleInputChange(e) {
    setSearchValue(e.target.value);
  }

  function renderFullHeader() {
    return (
      <Header>
        <LogoContainer src={LogoSvg} alt="logo" onClick={handleLogoClick} />
        <ExploreButton onClick={handleExploreButtonClick}>
          <FiCompass color="#00ADEF" size={20} />
          <ButtonText>Explorar</ButtonText>
        </ExploreButton>
        <InputContainer>
          <FiSearch color="#00ADEF" size={24} />
          <SearchInput
            type="text"
            placeholder="O que você está procurando?"
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
            value={searchValue}
          />
        </InputContainer>

        {isLoggedIn ? (
          <>
            <div style={{ display: 'flex' }}>
              <Link to="/free-projects">Gratuitos</Link>
              <Link to={`/${userType}/profile`}>
                {`${userName}${userType === 'designer' ? ' - Designer' : ''}`}
              </Link>
            </div>
            {userImage ? (
              <UserImage
                src={userImage}
                alt="Imagem do usuário"
                style={{ objectFit: 'cover' }}
              />
            ) : (
              <FaUserCircle size={34} color="#9a9a9a" />
            )}
          </>
        ) : (
          <>
            <div>
              <Link to="/free-projects">Gratuitos</Link>
              <Link to="/register">Cadastre-se</Link>
            </div>
            <Button text="Login" primary onClick={handleLoginButtonClick} />
          </>
        )}
      </Header>
    );
  }

  function renderMinifiedHeader() {
    return (
      <Header style={{ justifyContent: 'space-between' }}>
        <LogoContainer
          open
          src={LogoSvg}
          alt="logo"
          onClick={handleLogoClick}
        />
        <InputContainer>
          <FiSearch color="#00ADEF" size={24} />
          <SearchInput
            type="text"
            placeholder="O que você está procurando?"
            onKeyUp={handleInputKeyUp}
            onChange={handleInputChange}
            value={searchValue}
          />
        </InputContainer>
        <BurguerMenu />
      </Header>
    );
  }

  return (
    <Container>
      {windowWidth > 980 ? renderFullHeader() : renderMinifiedHeader()}
    </Container>
  );
};

export default HeaderComponent;
