import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import {
  Container,
  Headline,
  CheckboxContainer,
  Footer,
  modalStyles,
  Error,
} from './styles';
import Button from '../Button';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function TermsModal({ isOpen, onClose, onAccept, text, title }) {
  const [isChecked, setChecked] = useState(false);
  const [error, setError] = useState('');

  function handleCheckInputChange() {
    setChecked(!isChecked);
  }

  function handleAcceptButtonClick() {
    setError('');

    if (!isChecked) {
      setError('Você precisa aceitar para continuar');
      return;
    }

    onAccept();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Denunciar projeto"
      style={modalStyles}
      closeTimeoutMS={500}
    >
      <Container>
        <Headline>{title}</Headline>
        {text}
        <Footer>
          <div>
            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckInputChange}
              />
              <p>
                Ao clicar em aceito você está concordando com os termos
                descritos acima
              </p>
            </CheckboxContainer>
            {error && <Error>{error}</Error>}
          </div>

          <Button text="Aceito" primary onClick={handleAcceptButtonClick} />
        </Footer>
      </Container>
    </Modal>
  );
}

TermsModal.propTypes = propTypes;

export default TermsModal;
